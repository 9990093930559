import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { Routes, Route, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Tabs, Tab, Box, Toolbar, IconButton, Menu, MenuItem, Drawer, Paper, Avatar, Slide, SlideProps, Snackbar, Alert, Button, ButtonBase, CircularProgress, Skeleton, Link, Stack } from '@mui/material';
import { collection, doc, onSnapshot, query, where, getDocs, getDoc, DocumentData, Query, DocumentReference } from 'firebase/firestore';
import { User as FBUser, signOut } from 'firebase/auth';
import { Club, Colors, Invitation, Match, User, paths } from '@monorepo/shared';
import { AccountCircle, InsertInvitation } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TopBar } from 'shared/components/TopBar';
import { Sidebar } from 'shared/components/SideBar';
import { AppProvider, useAppContext } from 'contexts/AppContext';
import { SettingsModal } from 'app/routes/Settings';
import { TrainerSchedule } from 'app/routes/TrainerSchedule';
import { Welcome } from './Welcome';
import { useClub } from 'contexts/ClubContext';
import { useUser } from 'contexts/UserContext';
import { LoadingWrapper } from 'shared/components/LoadingWrapper';
import { useInvitations } from 'contexts/InvitationsContext';
import { useReferees } from 'contexts/RefereesContext';
import { useMatches } from 'contexts/MatchesContext';

const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: Colors.lightGrey,
  height: '100vh',  // Ensure it takes the full height of the viewport
  overflow: 'hidden',  // Prevent parent from scrolling
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: !open ? 0 : `${drawerWidth}px`,
}));

const fullPageRoutes = ["/home/users/statistics", "/home/overview"]; // Add routes that need full-page layout


const Home = () => {
  const [open, setOpen] = useState(true);

  const {club, clubSubscription } = useClub();
  const {user, userInvitations, currentInvitation, loadingCurrenInvitation, loadingUser, isAdmin} = useUser();

  const [pathName, setPathName] = useState("");

  const {openSideBar, setOpenSideBar} = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {

    setPathName(location.pathname);

  }, [currentInvitation, location.pathname, user])

  useEffect(() => {
    if (currentInvitation?.role === "trainer" && !location.pathname.includes("settings")) {
      navigate("trainer");
    } else if (location.pathname.includes("/home/trainer")) {
      navigate("/home/schedule");
    }
  }, [currentInvitation?.role, location.pathname, navigate])


  const hasPaid = () => (clubSubscription?.dueDate.toDate() || new Date()) > new Date()

  const noAccessMessage = () => {
    if (user?.activeClub === "") {
      return "Ingen tillagd klubb, lägg till i inställningar"
    }
    if (isAdmin) {
      return;
    }

    if (!hasPaid()) {
      return <span>Klubb har ej betalat, <Link onClick={() => navigate("/settings/subscriptions")}>betala nu</Link> för att låsa upp tillgång</span>
    }
    if (userInvitations.find(invitation => invitation.clubId === user?.activeClub)?.status !== "accepted") {
      return "Klubb har ej accepterat inbjudan, kontakta klubbansvarig eller avakta"
    } /// fix for trainers or people who dont have access
  }

  const isFullPage = fullPageRoutes.includes(location.pathname);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"sv"}>
      <Stack 
        direction={"row"} 
        spacing={openSideBar ? 2 : 0} 
        sx={[
          { 
            flex: 1, 
            width: '100%', 
            height: "100dvh",  
            bgcolor: Colors.primary, 
            transition: "padding 0.5s, margin 0.5s, width 0.3s ease-in-out", 
          }, 
          openSideBar 
            ? { p: 1, pl: 2, pt: 2 } 
            : { p: 0 } 
        ]}
      >        

        <Sidebar isClub={currentInvitation?.role !== "trainer" && club !== undefined}  />
        <LoadingWrapper 
          loading={loadingUser || loadingCurrenInvitation}
          noAccessMessage={noAccessMessage()}
          > 
          <Paper elevation={0} variant={isFullPage ? "elevation" : "outlined"} sx={[!openSideBar && {borderRadius: 0}, {backgroundColor: isFullPage ? "transparent" : "white", overflow: "hidden", height: "100%", width: "100%",maxHeight: "100%", display: "flex", flexDirection: "column",}]}>
            <Outlet />
          </Paper>
        </LoadingWrapper>
      </Stack>
    </LocalizationProvider>
  );
};

export default Home;
