import React, { useEffect, useState } from "react";
import {  Colors, Invitation, Match, Referee, refereeStatus } from "@monorepo/shared";
import { UserTable } from "features/users/components/UserTable";
import { EditReferee } from "features/users/components/EditReferee";
import { useAppContext } from "contexts/AppContext";
import { useMatches } from "contexts/MatchesContext";
import { Box, Card, CardContent, Typography, Grid2, keyframes, Stack, Paper, colors } from "@mui/material";
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart, PieChart, useDrawingArea } from "@mui/x-charts";
import { OpenSideBarButton } from "shared/components/OpenSideBarButton";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useClub } from "contexts/ClubContext";
import styled from "@emotion/styled";
import { useStatistics } from "../hooks/statistics";

export const statusColor = (status: refereeStatus) => {
  switch (status) {
    case "invite": return Colors.lightGrey;
    case "accepted": return Colors.accept;
    case "done": return Colors.darkBlue; 
    case "paid": return Colors.primary; 
    case "withdrawn": return Colors.warning; 
    default: return Colors.deny;
  }
}

const statusToTitle = (status: refereeStatus) => {
  switch (status) {
    case "invite" : return "Inbjudna";
    case "accepted": return "Accepterade";
    case "done": return "Attesterade";
    case "paid": return "Betalda";
    case "declined": return "Nekade";
    case "withdrawn": return "Tillbakadragna";
    default: return "";
  }
}

export const Statistics = () => {


  const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(10, "months"));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().add(2, "months"));

  const {monthlyPayments, monthlyUnpaid, monthlyPredicted, statusDistribution, months, paymentInfo, matchChartData} = useStatistics(startDate, endDate);

  return (
      <Grid2 container spacing={2} padding={1} overflow={"auto"}>
        {/* Sidebar/Card with Filters & Payment Info */}
        <Grid2 size={{xs: 12, md: 12}} sx={{ display: "flex", flexDirection: "column" }}>
          <Paper >
            <Stack direction="row" width="100%" padding={1} spacing={2} alignItems="center" justifyContent={"space-between"}>
              <Stack direction="row"   spacing={1} alignItems="center">
                <OpenSideBarButton />
                <Typography variant="h6" fontWeight="bold">Statistik</Typography>
              </Stack>

              <Stack direction="row"  spacing={5} alignItems="center">

                <DatePicker value={startDate} onChange={(newDate) => setStartDate(newDate!)} label="Start" views={['year', 'month', ]} />

                <DatePicker value={endDate} onChange={(newDate) => setEndDate(newDate!)} label="Slut" views={['year', 'month', ]} />
              </Stack>
            </Stack>
          </Paper>
        </Grid2>



        {/* Monthly Payments Chart */}
        <Grid2 size={{xs: 12, md: 12}} sx={{ display: "flex" }}>
          <Paper sx={{ flex: 1, padding: 2 }}>
            <Typography variant="h6" fontWeight="bold">Utbetalningar</Typography>
            <LineChart
              xAxis={[{ data: months || [], scaleType: "band" }]}
              series={[
                { data: monthlyPredicted, area: true,  color: Colors.darkBlue, label: `Förväntat belopp: ${paymentInfo.predicted}kr` },
                { data: monthlyPayments, area: true, color: "darkgreen", label: `Betalat belopp: ${paymentInfo.paid}kr` },
                { data: monthlyUnpaid, area: true, color: "darkred", label: `Obetalt belopp: ${paymentInfo.unpaid}kr` },
              ]}
              height={300}
              slotProps={{
                legend: {
                  direction: 'column',
                  position: { vertical: 'top', horizontal: 'right' },
                  padding: 0,
                },
              }}
            />
          </Paper>
        </Grid2>
        {/* Monthly Matches Chart */}
        <Grid2 size={{xs: 12, md: 7}} sx={{ display: "flex" }}>
          <Paper sx={{ flex: 1, padding: 2 }}>
            <Typography variant="h6" fontWeight="bold">Antal matcher</Typography>
            <BarChart
              xAxis={[{ data: months || [], scaleType: "band" }]}
              series={
                matchChartData?.map((data, index) => ({
                  ...data,
                  color: [Colors.primary, Colors.darkBlue, Colors.lightestBlue, Colors.lightGrey][index % 4],
                })) || []
              }
              height={350}
            />
          </Paper>
        </Grid2>
        {/* Match Status Distribution Chart */}
        <Grid2 size={{xs: 12, md: 5}} sx={{ display: "flex" }}>
          <Paper sx={{ flex: 1, padding: 2 }}>
            <Typography variant="h6" fontWeight="bold" >Matchfördelning</Typography>
            <PieChart
              sx={{marginTop: "auto"}}
              height={300}
              series={[{
                data: Object.entries(statusDistribution).map(([status, amount], index) => ({
                  id: index,
                  value: amount,
                  label: statusToTitle(status as refereeStatus),
                  color: statusColor(status as refereeStatus),
                })),
                innerRadius: "50%",
              }]}
              slotProps={{legend:{       position: {
                vertical: 'top',
                horizontal: 'right',
              }, } }}
            />

          </Paper>
        </Grid2>

      </Grid2>

  );
};