import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "firebase/auth"
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore"
import { getStripePayments } from "@invertase/firestore-stripe-payments";
import { getFunctions } from 'firebase/functions';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB--5rQz_1Au6x1wMYWNlWma5Y14txdp4s",
  authDomain: "reffi-c14a2.firebaseapp.com",
  projectId: "reffi-c14a2",
  storageBucket: "reffi-c14a2.firebasestorage.app",
  messagingSenderId: "877995085941",
  appId: "1:877995085941:web:80aaa0431fec9e03f8a83c",
  measurementId: "G-1TCNBXPHFR"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
//connectAuthEmulator(auth, "http://localhost:9099");

export const db = getFirestore(app);
//connectFirestoreEmulator(db, "localhost", 8080);

export const functions = getFunctions(app, "europe-west2");

export const storage = getStorage(app);


export const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});
