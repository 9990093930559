import * as React from 'react';
import { AppBar, Avatar, Button, IconButton, Menu, MenuItem, Paper,  Toolbar, Typography, useScrollTrigger } from "@mui/material"
import { useScreenSize } from '../hooks/screenSize';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { getReferralFromUrl } from '../utils/refferal';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from 'config/firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { paths } from '@monorepo/shared';

type TopBarProps = {
  topRef: React.RefObject<HTMLElement>,
  clubsRef: React.RefObject<HTMLElement>, 
  pricesref: React.RefObject<HTMLElement>, 
  aboutRef: React.RefObject<HTMLElement>, 
  contactRef: React.RefObject<HTMLElement>,
  questionsRef: React.RefObject<HTMLElement>
}

export const TopBar = ({topRef, clubsRef, pricesref, aboutRef, contactRef, questionsRef} : TopBarProps) => {
  const navigate = useNavigate();
  const {smallScreen} = useScreenSize();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScroll = (sectionRef: React.RefObject<HTMLElement>) => {
    sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const clubUserDoc = await getDoc(doc(db, paths.clubUsers, user.uid));
        if (clubUserDoc.exists()) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const login = () => {
    if (isLoggedIn) {
      navigate("/home")
    } else {
      handleNavigate("login")
    }
  }

  const handleNavigate = (page: string) => {
    const referralCode = getReferralFromUrl();
    const url = referralCode ? `/${page}?referral=${referralCode}` : `/${page}`;
    navigate(url, { replace: true });
  }

  return (
    <AppBar position="sticky" elevation={useScrollTrigger({disableHysteresis: true,threshold: 100}) ? 4 : 0} color='primary'>
      <Toolbar>
        <Button onClick={() => handleScroll(topRef)}>
          <Avatar sx={{ width: 48, height: 48 }} src={require("../../../assets/images/icon.png")}></Avatar>
          <Typography color='white' variant="h4" fontStyle={"italic"} component="div" > 
            REFFI
          </Typography>      
        </Button>             
        {!smallScreen ? <div style={{width: "100%", flexDirection: "row", display: "flex", justifyContent: "space-between"}}>
            <div style={{ paddingLeft: 15, flexGrow: 1, paddingTop: 3, display: 'flex', gap: '15px' }}>
              <Button color="inherit" onClick={() => handleScroll(clubsRef)}>För föreningen</Button>
              <Button color="inherit" onClick={() => handleScroll(aboutRef)}>Om oss</Button>
              <Button color="inherit" onClick={() => handleScroll(pricesref)}>Priser</Button>
              <Button color="inherit" onClick={() => handleScroll(questionsRef)}>FAQ</Button>
              <Button color="inherit" onClick={() => handleScroll(contactRef)}>Kontakt</Button>
            </div>
            <div style={{gap: 10, flexDirection: "row", display: "flex"}}>
              <Button variant="contained" onClick={login}>Logga In</Button>
              <Button variant="contained" onClick={() => handleNavigate("signup")}>Kom Igång!</Button>
            </div> 
          </div>
          : <div style={{width: "100%", flexDirection: "row", display: "flex", justifyContent: "flex-end"}} >
                        <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => {handleNavigate("login"); handleClose()}}>Logga In</MenuItem>
              <MenuItem onClick={() => {handleNavigate("signup"); handleClose()}}>Kom Igång</MenuItem>
            </Menu>
          </div>
          </div>}
      </Toolbar>
    </AppBar>
  )
}