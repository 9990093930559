import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Fade,
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { GeneralSettings } from "../../features/settings/components/GeneralSettings";
import { Questions } from "../../features/settings/components/Questions";
import { BankSettings } from "../../features/settings/components/BankSettings";
import { useNavigate } from "react-router-dom";
import { ClubEditor } from "features/settings/components/ClubEditor";
import { useClub } from "contexts/ClubContext";
import { useUser } from "contexts/UserContext";
import { Colors } from "@monorepo/shared";

import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';

const style = {
  position: "absolute" as "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -30%)",
  boxShadow: 24,
  height: "80vh",
  width: { md: "80vw", xs: "100vw" },
};

const steps = [
  {
    label: "Välkommen!",
    description: `Kul att du börjat använda Reffi!`,
  },
  {
    label: "Klubbinställningar",
    description: "Välj inställningar",
  },
  {
    label: "Frågor/svar till domare",
    description: `Här kan du skapa frågor och svar angående er klubb.`,
  },
  {
    label: "Klar!",
    description: ``,
  },
];

const ClubProgress = ({ invitation, isNewClub, club }: any) => {
  
  if (!invitation) {
    return <ClubEditor />
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="300px" 
      padding={3} 
    >
      {
        <Stack spacing={2} width="100%">
          {!isNewClub ? (
            <Typography variant="h6" align="center">
              Skickade inbjudan till klubb: {invitation.clubName}
            </Typography>
          ) : !club ? (
            <Stack alignItems="center">
              <Typography variant="h6" align="center">
                Skapar klubb: {invitation.clubName}
              </Typography>
              <LinearProgress sx={{ width: "80%", marginTop: 2 }} />
            </Stack>
          ) : (
            <Stack>
              <Typography variant="h6" align="center" sx={{ margin: 3 }}>
                Skapade klubb: {invitation.clubName}
              </Typography>
              <Typography variant="h6" align="center" >
                Be era domare att ladda ner Reffi i sina mobiler och lägga till er klubb!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent={"center"} sx={{ margin: 3 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AppleIcon />}
                href="https://apps.apple.com/us/app/reffi/id6720720771"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textTransform: 'none' }}
              >
                Ladda ner på App Store
              </Button>
              <Button
                variant="contained"
                color="success"
                startIcon={<AndroidIcon />}
                href="https://play.google.com/store/apps/details?id=se.reffi"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textTransform: 'none' }}
              >
                Ladda ner på Google Play
              </Button>
            </Stack>
            </Stack>
          )}
        </Stack>
      }
    </Box>
  );
};

export const Welcome = () => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const { user, userInvitations } = useUser();
  const { club } = useClub();
  const [formattedSteps, setFormattedSteps] = useState(steps);

  const newClubInvitation = useCallback(() => {
    if (!user || !user.activeClub) return undefined;
    return userInvitations.find((invitation) => user.activeClub === invitation.clubId);
  }, [user, userInvitations]);

  const isNewClub = useCallback(() => {
    const invitation = newClubInvitation();
    if (invitation) {
      return invitation.status === "accepted" && invitation.role !== "trainer";
    }
    return false;
  }, [newClubInvitation]);

  useEffect(() => {
    if (!isNewClub()) {
      setFormattedSteps([steps[0], steps[steps.length - 1]]);
    } else {
      setFormattedSteps(steps);
    }
  }, [isNewClub]);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const renderStepContent = () => {
    const invitation = newClubInvitation();

    if (activeStep === 0) {
      return (
        <Box >
          <Typography sx={{ margin: 5 }} variant="h6">
            Kul att du har börjat använda Reffi! Det här är en guide till hemsidan och kommer tillåta dig att
            skräddarsy din upplevelse! Den är helt frivillig att göra och allt du finner här kan du även ändra under inställningar-fliken högst upp till höger.
            Vi rekommenderar varmt att genomföra guiden för optimal funktion. Första steget är att välja en klubb nedan!
          </Typography>
          <Divider /> 
          <Box padding={10}>
            <ClubProgress invitation={invitation} isNewClub={isNewClub()} club={club} />
          </Box>
        </Box>
      );
    }
    if (!isNewClub()) {
      return (
        <Stack sx={{ margin: 5 }} spacing={5}>
          <Typography  variant="h6">
            Välkommen till Reffi – din resa börjar nu! 🎉
          </Typography>
          <Typography variant="h6">
            Du har nu gått med i en befintlig klubb. Om du behöver lägga till fler klubbar eller ändra dina inställningar kan du göra det när som helst.
          </Typography>
          <Typography variant="h6">
            Lycka till, och hör av dig om du har några frågor!
          </Typography>
        </Stack>
      );
    }

    switch (activeStep) {
      case 1:
        return( 
          <Stack>
            <Typography variant={"h6"} padding={5}>
              Här finner du generella inställningar om klubben exempelvis, vem domaren ska kontakta eller ersättning för varje match.
            </Typography>
            <Divider /> 

            <GeneralSettings />
          </Stack>
          );
      case 2:
        return <Questions />;
      case 3:
        return (
          <Stack sx={{ margin: 5 }} spacing={3}>
            <Typography  variant="h6">
              Välkommen till Reffi – din resa börjar nu! 🎉
            </Typography>
            <Typography  variant="h6">
              Som ny klubb får ni första månaden kostnadsfritt för att upptäcka och testa alla funktioner i tjänsten utan några bekymmer. Observera att Reffi är en betaltjänst. För att fortsätta använda tjänsten efter den första kostnadsfria månaden behöver ni teckna ett abonnemang. Det kan ni fixa här eller senare under inställningar!
            </Typography>
            <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
              <Button variant="contained" onClick={() => navigate("/settings/subscriptions")}>
                Teckna abonnemang!
              </Button>
            </Stack>
            <Typography variant="h6">
              Kom ihåg, ni har alltid friheten att avsluta abonnemanget när som helst, helt utan bindningstid.
            </Typography>
            <Typography variant="h2" padding={7} textAlign={"center"}>
              Välkomna <span style={{color: Colors.primary}}>{invitation?.clubName}!</span>
            </Typography>
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Modal closeAfterTransition open={true}>
      <Fade in={true}>
        <Paper sx={{ ...style, display: "flex", flexDirection: "column" }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: 2 }}>
            <Typography variant="h6">{formattedSteps[activeStep].label}</Typography>
            <IconButton disabled={activeStep === 0} onClick={() => navigate("/home")}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            borderTop="1px solid lightgray"
            direction="row"
            flex={1}
            maxHeight="100%"
            overflow="hidden"
          >
            {!isMobile && (
              <Box
                sx={{
                  width: 300,
                  flex: 1,
                  padding: 2,
                  borderRight: "1px solid lightgray",
                }}
              >
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  sx={{
                    width: 280,
                  }}
                >
                  {formattedSteps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel>{step.label}</StepLabel>
                      {activeStep === index && (
                        <StepContent>
                          <Typography>{step.description}</Typography>
                          <Box sx={{ mt: 2 }}>
                            <Button
                              variant="contained"
                              disabled={(index === 0 && !newClubInvitation()) || (newClubInvitation()?.status === "accepted" && !club)}
                              onClick={() => (index === formattedSteps.length - 1 ? navigate("/home") : handleNext())}
                              sx={{ mr: 1 }}
                            >
                              {index === formattedSteps.length - 1 ? "Klar" : "Fortsätt"}
                            </Button>
                            <Button disabled={index === 0} onClick={handleBack}>
                              Tillbaka
                            </Button>
                          </Box>
                        </StepContent>
                      )}
                    </Step>
                  ))}
                </Stepper>
              </Box>
            )}
            <Box width="100%" height="100%" maxHeight="100%" overflow={"scroll"}>
              {renderStepContent()}
            </Box>
          </Stack>

          {/* Footer for mobile */}
          {isMobile && (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ padding: 2, borderTop: "1px solid lightgray" }}
            >
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Tillbaka
              </Button>
              <Button
                variant="contained"
                onClick={activeStep === formattedSteps.length - 1 ? () => navigate("/home") : handleNext}
              >
                {activeStep === formattedSteps.length - 1 ? "Klar" : "Fortsätt"}
              </Button>
            </Stack>
          )}
        </Paper>
      </Fade>
    </Modal>
  );
};
