// InvitationsContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase/firebase';
import { Invitation, paths } from '@monorepo/shared';
import { useUser } from './UserContext';
import { getDocuments } from 'utils/getDocs';
import { addSubscription } from "./ManageSubscriptions";
import { useClub } from './ClubContext';

interface InvitationsState {
  invitations: Invitation[];
  loadingInvitations: boolean;
}

const InvitationsContext = createContext<InvitationsState | undefined>(undefined);

export const InvitationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { currentInvitation } = useUser();
  const { club } = useClub();
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [loadingInvitations, setLoadingInvitations] = useState(false);

  useEffect(() => {
    if (!club || club.id === "" || !currentInvitation || currentInvitation.role  === "trainer") return;

    try {
      setLoadingInvitations(true);
      const invitationRef = query(collection(db, paths.invitations), where('clubId', '==', club.id));
  
      const unsubscribe = onSnapshot(invitationRef, async () => {
        const newInvitations = await getDocuments(invitationRef) as Invitation[];
        setInvitations(newInvitations);
        setLoadingInvitations(false);
      });
      addSubscription(unsubscribe)
      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching invitations: ", error);
    }
  }, [club, currentInvitation]);

  return <InvitationsContext.Provider value={{ invitations, loadingInvitations }}>{children}</InvitationsContext.Provider>;
};

export const useInvitations = () => {
  const context = useContext(InvitationsContext);
  if (!context) {
    throw new Error('useInvitations must be used within an InvitationsProvider');
  }
  return context;
};
