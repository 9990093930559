import { refereeStatus } from "@monorepo/shared";


export const statusColor = (status: refereeStatus, tab: boolean) => {
  switch (status) {
    case "invite": return tab ? "default" : "warning";
    case "accepted": return tab ? "success" : "default";
    case "done": return tab ? "warning" : "info"; 
    case "paid": return tab ? "warning" : "success"; 
    case "withdrawn": return tab ? "error" : "error"; 
    default: return "error";
  }
}


export const statusToTitle = (status: refereeStatus, tab: boolean) => {
  switch (status) {
    case "invite" : return "Inbjuden";
    case "accepted": return tab ? "Accepterad" : "Ej Attesterad";
    case "done": return "Attesterad";
    case "paid": return "Betald";
    case "declined": return "Nekad";
    case "withdrawn": return "Tillbakadragen";
    default: return "Ej inbjuden";
  }
}

export const nextStatusTitle = (status: refereeStatus, tab: boolean) => {
  switch (status) {
    case "invite" : return "Acceptera";
    case "accepted": return tab ? "Ta bort" : "Attestera";
    case null: return tab ? "Bjuda in" : "Attestera";
    default: return "Ta bort";
  }
}