import { Match, MatchInvite } from "@monorepo/shared";
import { Box, Checkbox, Dialog, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid2, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { useClub } from "contexts/ClubContext";
import dayjs from "dayjs";
import { useState } from "react";


type MatchInfoEditorProps = {
  match: Match, 
  handleInputChange: (value: string | number | boolean | {[k: string]: MatchInvite}, field: keyof Match) => void
}

export const MatchInfoEditor = ({match, handleInputChange}: MatchInfoEditorProps) => {

  const {club} = useClub();

  return (
    <Grid2 container spacing={2} rowSpacing={4}>
      <Grid2 container spacing={16}  width={"100%"}alignItems="center" justifyContent={"space-between"}  display="flex">
        <Typography variant="h6" component="div" display="flex" alignItems="center">
          <span>{!match.id ? "Ny match" : "Uppdatera Match"}</span>
        </Typography>    
        <FormGroup>
          <FormControlLabel 
            control={<Checkbox checked={Boolean(match.miniTournament)} onChange={(e) => handleInputChange(e.target.checked, "miniTournament")} />} 
            label="Poolspel" 
          />
        </FormGroup>    
      </Grid2>
      {!match.miniTournament && <Grid2 size={6}>
        <TextField fullWidth label="Hemmalag" value={match.home} onChange={(e) => handleInputChange(e.target.value, "home")}></TextField>
      </Grid2>}
      {!match.miniTournament && <Grid2 size={6}>
        <TextField fullWidth label="Bortalag" value={match.away} onChange={(e) => handleInputChange(e.target.value, "away")}></TextField>
      </Grid2>}
      <Grid2 size={6}>
        <TextField fullWidth label={!match.miniTournament ? "Division" : "Ålder"} value={match.division} onChange={(e) => handleInputChange(e.target.value, "division")}></TextField>
      </Grid2>
      <Grid2 size={6}>
        <TextField fullWidth label="Plats" value={match.place} onChange={(e) => handleInputChange(e.target.value, "place")}></TextField>
      </Grid2>
      <Grid2 size={6}>
          <DatePicker
            sx={{width: "100%"}}
            label="Datum"
            value={match.time ? dayjs(match.time) : null}
            onChange={(value) => handleInputChange(value?.toISOString()!, "time")}
            />
      </Grid2>
      <Grid2 size={6}>
        <TimePicker
          sx={{width: "100%"}}
          label="Tid"
          onChange={(value) => handleInputChange(value?.toISOString()!, "time")}
          value={match.time ? dayjs(match.time) : null}
          />
      </Grid2>
      <Grid2 size={4}>
      <TextField 
        fullWidth 
        label="Ersättning per domare (kr)" 
        value={match.compensation ?? ""} 
        type="number" 
        inputMode="numeric" 
        onChange={(e) => handleInputChange(e.target.value === "" ? "" : parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0, "compensation")} 
        onBlur={() => {
          if (match.compensation === 0 || isNaN(match.compensation!)) {
            handleInputChange(0, "compensation");
          }
        }}
      />                  
    </Grid2>                  
      <Grid2 size={4}>
        <TextField  fullWidth label="Antal domare" value={match.referees || 1} type="number" onChange={(e) => handleInputChange(parseInt(e.target.value) >= 1 ? parseInt(e.target.value) : 1, "referees")}></TextField>
      </Grid2>
      <Grid2 size={4}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="matchType-label">Spelform</InputLabel>
          <Select
            fullWidth
            id="matchType"
            value={
              Object.keys(club?.settings.matchTypes || {}).includes(String(match.periodLength))
                ? match.periodLength
                : 0 
            }                       
            label="Spelform"
            placeholder="Välj Spelform"
            labelId="matchType-label"
            onChange={(e) => handleInputChange(e.target.value, "periodLength")}
            >
            <MenuItem value={0}>Döms ej</MenuItem>

            {Object.entries(club?.settings.matchTypes || {}).map(([id, mt]) => 
              <MenuItem key={id} value={parseInt(id)}>{mt.displayValue}</MenuItem>
            )}
          </Select>  
        </FormControl>                
      </Grid2>
      {true && <Grid2 size={12}>
        <TextField  
          fullWidth 
          label="Kommentar till domare" 
          value={match.comment || ""}
          onChange={e => handleInputChange(e.target.value, "comment")}
          multiline    
        />
      </Grid2>}
    </Grid2>
  )
  
}