import { DataGrid, GridColDef, GridPrintExportOptions, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid";
import { usePaymentData } from "../hooks/usePaymentData";
import { useCallback, useEffect, useState } from "react";
import { Colors, formatTime, MatchInvite, paths } from "@monorepo/shared";
import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase/firebase";
import { useAppContext } from "contexts/AppContext";
import { Box, Button, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { useClub } from "contexts/ClubContext";
import { filterBetweenDates } from "../utils/timeUtils";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import { OpenSideBarButton } from "shared/components/OpenSideBarButton";


const columns: GridColDef[] = [
  { field: 'id', headerName: '', width: 30, },
  { field: 'name', headerName: 'Namn', width: 200 },
  { field: 'phone', headerName: 'Telefon', flex: 1 },
  { field: 'time', headerName: 'Datum', flex: 1 },
  { field: 'division', headerName: 'Division', flex: 2 },
  { field: 'match', headerName: 'Match', flex: 2 },
  { field: 'accountNumber', headerName: 'Bankkontonummer', flex: 1 },
  { field: 'compensation', headerName: 'Ersättning',  headerAlign: "right",align: "right", flex: 1},
];

const columnsPDF: GridColDef[] = [
  { field: 'id', headerName: '', width: 30, },
  { field: 'name', headerName: 'Namn', flex: 1 },
  { field: 'time', headerName: 'Datum', width: 150 },
  { field: 'accountNumber', headerName: 'Bank nummer', flex: 1 },
  { field: 'compensation', headerName: 'Ersättning',  headerAlign: "right",align: "right", width: 100},
];

interface UnpaidProps {

}



export const Unpaid = () => {
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const {club} = useClub();
  const {unpaid} = usePaymentData();
  const {setStatusMessage, setOpenStatusMessage, setStatusMessageType} = useAppContext();
  const apiRef = useGridApiRef(); 

  const markAsPaid = useCallback(() => {
    if (!club) return;
    if (!selectedRows.length) return

    try {
      const matchInviteRef = collection(db, paths.matchInvites);
  
      selectedRows.forEach(async (selectedRowId) => {
        const matchInvite = unpaid[Number(selectedRowId)];
        const matchInviteData: MatchInvite = {
          id: matchInvite.id,
          name: matchInvite.name,
          refereeId: matchInvite.refereeId,
          clubId: matchInvite.clubId,
          matchId: matchInvite.matchId,
          status: "paid", // Updated status
          phoneNumber: matchInvite.phoneNumber,
          paid: {
            amount: matchInvite.compensation || 0,
            date: new Date().toISOString(),
          },
        };
        await updateDoc(doc(matchInviteRef, matchInvite.id?.toString()), matchInviteData);
      });
  
      setSelectedRows([]);
      setStatusMessage("Domare markade som betalda!");
    } catch {
      setStatusMessage("Misslyckades att markera domare!");
      setStatusMessageType("error");
    } finally {
      setOpenStatusMessage(true);
    }
  }, [club, selectedRows, setStatusMessage, unpaid, setStatusMessageType, setOpenStatusMessage]);

  const generatePDF = useCallback(() => {
    const doc = new jsPDF();
  
    // Add Header
    const addHeader = () => {
      doc.setFontSize(8);
      doc.text(new Date().toLocaleDateString(), 14, 10); // "Reffi" aligned with the date
      doc.text(`Reffi `, 120, 10); // Adjust X position
    };
  
    // Add Footer
    const addFooter = (pageNumber: number) => { 
      const pageCount = doc.getNumberOfPages();
      doc.setFontSize(8);
      doc.text(`${pageNumber} / ${pageCount}`, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10, { align: "right" });
      doc.text(`https://reffi.se`, 10, doc.internal.pageSize.height - 10, { align: "left" });

    };
  
    // Add the Header to the first page
    addHeader();
  
    // Define the table columns
    const pdfColumns = [
      { header: "ID", dataKey: "id" },
      { header: "Namn", dataKey: "name" },
      { header: "Datum", dataKey: "time" },
      { header: "Bank nummer", dataKey: "accountNumber" },
      { header: "Ersättning", dataKey: "compensation" },
    ];
  
    // Get rows for the PDF
    const pdfRows = selectedRows.map((selectedRowId) =>
      unpaid[Number(selectedRowId)]
    ).map((match, i) => ({
      id: i + 1,
      name: match.name,
      time: formatTime(match.time),
      accountNumber: match.accountNumber,
      compensation: `${match.compensation}kr`,
    }));
  
    // Add the table with plain styling and bottom border for rows
    autoTable(doc, {
      columns: pdfColumns,
      body: pdfRows,
      startY: 15, // Start below the header
      theme: "plain", // Remove grid lines
      styles: {
        halign: "center", // Center-align text
      },
      didDrawCell: (data) => {
          doc.setDrawColor(200, 200, 200); // Bottom border color
          doc.setLineWidth(0.2);
          doc.line(
            data.cell.x,                       // Start X
            data.cell.y + data.cell.height,    // Start Y
            data.cell.x + data.cell.width,     // End X
            data.cell.y + data.cell.height     // End Y
          );
      },
      columnStyles: {
        0: { halign: "left", cellWidth: 7 }, // Left-align ID
        4: { halign: "right", cellWidth: 30 }, // Right-align compensation
      },
      didDrawPage: (data) => {
        // Add Footer on each page
        const pageNumber = doc.getNumberOfPages();
        addFooter(pageNumber);
  
        // Add Header on subsequent pages
        if (pageNumber > 1) {
          addHeader();
        }
      },
    });
  
    // Save the PDF
    doc.save(`domararvode-${new Date().toLocaleDateString()}`);
  }, [selectedRows, unpaid]);

  const print = async () => {    
    const printOptions: GridPrintExportOptions = {
      hideFooter: true,
      hideToolbar: true,
    };
    apiRef.current.resetRowHeights();
    apiRef.current.setDensity("compact");
    apiRef.current.resize();
    await new Promise(resolve => setTimeout(resolve, 100)); 

    apiRef.current.exportDataAsPrint(printOptions)
  }

  const getRows = () => unpaid.map((match, i) => (
    {
      ...match,
      time: formatTime(match.time),
      compensation: match.compensation + "kr",
      match: match.home + "-" + match.away,
      id: i,
    }
  ))



  const getPDFRows = () => selectedRows.map(selectedRowId => unpaid[Number(selectedRowId)]).map((match, i) => (
    {
      name: match.name,
      accountNumber: match.accountNumber,
      time: formatTime(match.time),
      compensation: match.compensation + "kr",
      id: i + 1,
    }
  ))

  return (
    <Box sx={{ width: '100%', height: "100%" }}>


        <Stack direction="row" justifyContent="space-between" alignItems="center" p={1}>
          <Stack direction={"row"} alignItems={"center"} sx={{ whiteSpace: "nowrap", pt: 1, pl: 1}}>
            <OpenSideBarButton />
            <Typography variant="h6" fontWeight="bold" >
              {unpaid.length} Obetalda Matcher
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Button variant="contained" color="secondary" sx={{ color: Colors.primary }} onClick={() => print()}>
              Generera pdf
            </Button>
            <Button variant="contained" color="secondary" sx={{ color: Colors.primary }} onClick={() => {}}>
              Generera bankfil
            </Button>
            <Divider orientation="vertical" flexItem  />
            <Tooltip title="Lägg till match">
              <Button variant="contained" color="primary" onClick={markAsPaid}>
                Markera som betalda
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      <Divider  />

      <DataGrid
        rows={getRows()}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        pageSizeOptions={[5, 10]}
        checkboxSelection={true}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
        }}
        localeText={
        {noRowsLabel: "Inga obetalda domare", 
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from}-${to} av ${count === -1 ? `mer än ${to}` : count}`,
          },
        }}
        sx={{ border: 0 }}
      />
    <div style={{width: "900px", display: "hidden"}}>
      <DataGrid
        apiRef={apiRef}
        rows={getPDFRows()}
        rowSelection={false}
        disableColumnFilter
        disableColumnMenu
        columns={columnsPDF}
        pageSizeOptions={[5, 10]}
        localeText={
          {noRowsLabel: "Inga betalningar i den angivna tidsperioden", 
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `${from}-${to} av ${count === -1 ? `mer än ${to}` : count}`,
            },
          }}
        sx={{ border: 0, display: "hidden" }}
      />
    </div>
</Box>
  )
}