import { Match, MatchInvite, refereeStatus } from "@monorepo/shared";


export const groupByRefereeStatus = (matches: Match[], matchInvites: { [matchId: string]: MatchInvite[] }) => {
  return matches.reduce((acc, match) => {
    const invites = matchInvites[match.id!] || []; // Get invites for the match

    invites.forEach(inv => {
      if (inv.status) {
        if (!acc[inv.status]) {
          acc[inv.status] = 0
        }
        acc[inv.status] += 1
      }
    })
    return acc;
  }, {} as {[refStatus: string] :number});
};