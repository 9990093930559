import React from "react";
import { Match, MatchInvite, } from "@monorepo/shared";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade,  FormControlLabel, FormGroup, Modal, Paper,Stack, Typography } from "@mui/material";
import 'dayjs/locale/sv'

import { useAppContext } from "contexts/AppContext";
import { useClub } from "contexts/ClubContext";
import { useMatches } from "contexts/MatchesContext";
import { MatchInfoEditor } from "./MatchInfoEditor";
import { removeMatch, saveMatch } from "../utils/saveMatch";

export const style = {
  width: { xs: "100%", sm: "90%", md: "80%", lg: "60%" },
  position: 'absolute' as 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -30%)',
  boxShadow: 24,
  padding: "3%",  
};

// Invites only for examples to prevent firebase save 
export const MatchEditorTrainer = ({ match, open, onClose}: {match: Match, open: boolean, onClose: () => void}) => {
  const [updatedMatch, setUpdatedMatch] = React.useState(match);
  const [openDelete, setOpenDelete] = React.useState(false);


  const {setStatusMessage, setOpenStatusMessage, setStatusMessageType} = useAppContext();
  const {club} = useClub();
  const {matchInvitations} = useMatches();

  React.useEffect(() => {
    setUpdatedMatch({...match});
    setOpenDelete(false);
  }, [match, setUpdatedMatch])

  const handleInputChange = (value: string | number | boolean | {[k: string]: MatchInvite}, field: keyof Match) => {
    setUpdatedMatch(prev => ({...prev!, [field] : value}))
  };


  const saveTrainerMatch = () => {
    if (!club?.id) return

    const match = {...updatedMatch, everyone: true}
    saveMatch(match, setStatusMessage, setStatusMessageType, setOpenStatusMessage, onClose, {}, club)
  }


  return (
    <Modal disableAutoFocus={true} closeAfterTransition open={open} onClose={onClose}>
      <Fade in={open}>
        <Paper sx={style} >
          <Box>
            <MatchInfoEditor handleInputChange={handleInputChange} match={updatedMatch}/>       
          
            <Stack alignSelf={"center"} justifyContent={"space-between"} spacing={3} direction={"row"} marginTop={2}>
              <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Radera Match"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Tar bort en match, går inte att ångra. 
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenDelete(false)} autoFocus>Avbryt</Button>
                  <Button onClick={() => removeMatch(updatedMatch, setStatusMessage, setStatusMessageType, setOpenStatusMessage, onClose, matchInvitations, club)}> Radera </Button>
                </DialogActions>
              </Dialog>
              <Button variant="contained" color="error" disabled={!updatedMatch.id} onClick={() => setOpenDelete(true)}>
                Radera
              </Button>
              <Stack alignSelf={"center"} justifyContent={"center"} spacing={3} direction={"row"} margin={2}>
                <Button variant="contained" onClick={onClose}>
                  Avbryt
                </Button>
                <Button variant="contained" onClick={saveTrainerMatch}>
                  Bjud in domare och spara
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  )
}