import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemText, Collapse, Box, colors } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "@monorepo/shared";

interface SidebarSectionProps {
  title: string;
  icon?: React.ReactNode;
  basePath: string;
  subLinks: { label: string; path: string }[];
}

const SidebarSection: React.FC<SidebarSectionProps> = ({ title, icon, basePath, subLinks }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const isActive = location.pathname.startsWith(basePath);
  const [open, setOpen] = useState(isActive);

  useEffect(() => {
    if (isActive) {
      setOpen(true);
    }
  }, [isActive]);

  return (
    <>
      {/* Main Section */}
      <ListItem 
        onClick={() => setOpen(!open)} 
        
        sx={{ 
          color: Colors.white,
          borderRadius: "8px",
          paddingLeft: 1.5,
          pb: 0.6,
        }}
      >
        {icon}
        <ListItemText primary={title} sx={{pl: 1}}/>
        {open ? <ExpandLess fontSize="small"/> : <ExpandMore fontSize="small"/>}

      </ListItem>

      {/* Sub-links */}
      <Collapse in={open}>
        <List component="div" disablePadding sx={{  paddingRight: 1, paddingLeft:1 }}>
          {subLinks.map(({ label, path }) => {
            const isSelected = location.pathname === path;

            return (
              <ListItem 
                key={path} 
                onClick={() => navigate(path)} 
                sx={{ 
                  backgroundColor: isSelected ? Colors.white : "transparent",
                  paddingTop: 0,
                  paddingBottom: 0,
                  borderRadius: "6px",
                  boxShadow: isSelected ? "0px 4px 8px rgba(0, 0, 0, 0.2)" : "none", // Elevation effect
                }}
              >
                <ListItemText primary={label} sx={{ color: isSelected ? Colors.primary : Colors.white, borderLeft: `1px solid ${isSelected ? Colors.primary : Colors.white}`, marginTop:0, marginBottom: 0, padding:"5px" }} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export default SidebarSection;
