import { Colors, Invitation, Referee } from "@monorepo/shared";
import { Chip, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useClub } from "contexts/ClubContext";
import { useMatches } from "contexts/MatchesContext";
import { useReferees } from "contexts/RefereesContext";
import { useUser } from "contexts/UserContext";
import { useMatchesCount } from "features/users/hooks/refereeMatchescount";
import { useEffect, useState } from "react";
import { getDisplayValue } from "shared/utils/defaultMatchTypes";
import { roleToText } from "shared/utils/roles";
import InfoIcon from '@mui/icons-material/Info';

export const UserTable = ({
  data,
  columns,
  onRowClick
}: {
  data: Invitation[] | (Invitation & Referee)[],
  columns: string[],
  onRowClick: (item: Invitation) => void
}) => { 

  const {club} = useClub();
  const {referees} = useReferees();

  const matchesCount = useMatchesCount();

  return (
    <TableContainer sx={{ maxHeight: "100%", overflowY: "auto" }} >
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap'}}></TableCell>
            {columns.map((col, index) => 
              <TableCell key={col} align={index === columns.length - 1 ? "right" : "inherit"} sx={{ whiteSpace: 'nowrap',}}>
                <span style={{fontWeight: "bolder"}}>{col}</span>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, i) => {
            const referee = referees.find(referee => referee.id === item.userId)!;
            const user = {...item, ...referee}
            return (
              <TableRow key={i} hover onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
                <TableCell>
                <Stack direction={"row"} alignItems={"center"}>

                  <Typography>
                    {i + 1}
                  </Typography>

                  {item.comment && <Tooltip title={"Kommentar: " + item.comment}>
                    <InfoIcon  fontSize='small' sx={{ marginLeft: 2, color: Colors.primary }}/>
                  </Tooltip>}
                </Stack>

                </TableCell>
                <TableCell>{item.name}</TableCell>
                {(user.status === "accepted" || user.role !== "referee") && <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  {"permissions" in item ? 
                    (!user.external ? (user.permissions || []).map((permission, i) => {
                      const val = getDisplayValue(permission.toString(), club?.clubType);
                      return <Chip key={i} label={val} style={{margin: 2}}/>
                    }) : "Extern domare")
                      :
                    roleToText(user.role, user.status)
                  }
                </TableCell>}
                <TableCell>{user.phoneNumber}</TableCell>
                <TableCell align={"accountNumber" in user ? "inherit" : "right"}>{user.email}</TableCell>
                {user.role === "referee" && user.status === "accepted" && <TableCell>{matchesCount[user.userId]?.invite || 0}</TableCell>}

                {"socialSecurityNumber" in user && <TableCell align={user.status === "accepted" ? "inherit" : "right"}>{user.socialSecurityNumber}</TableCell>}
                {user.status === "accepted" && "bank" in user &&<TableCell>{user.bank}</TableCell>}
                {user.status === "accepted" && "accountNumber" in user && <TableCell align="right">{user.clearingNumber}-{user.accountNumber}</TableCell>}
              </TableRow>
            )
          })}

        </TableBody>
      </Table>
    </TableContainer>
  )
};