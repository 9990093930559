import { Club, emptyMatch, Invitation, Match, MatchInvite, matchType, Referee } from "@monorepo/shared";

const THREE_HOURS_MS = 3 * 60 * 60 * 1000; // 3 hours in milliseconds

export const generateCompensation = ( settings: Club["settings"], periodLength?: number) => {
  if (!periodLength) {
    return undefined
  }
  const compensation = settings.matchTypes[periodLength.toString()]?.compensation || 0;
  return settings.splitCompensation ? compensation / (settings.referees || 1) : compensation;
}

export const generateReferee = (
  referees: Referee[], 
  refereeStats: { [id: string]: number }, 
  match: Match, 
  exclude: MatchInvite[]
) => {
  const refereesWithPermission: Referee[] = referees
    .filter(referee => (referee.permissions || []).includes(match.periodLength || 0))
    .filter(ref => !exclude.some(invite => invite.refereeId === ref.id)); // Ensure unique referee per match

  
  if (refereesWithPermission.length <= 0) {
    return null;
  }

  // Choose referee with the least matches assigned
  return refereesWithPermission.reduce((acc, referee) => 
    ((referee.matches || 0) + refereeStats[referee.id!] < (acc.matches || 0) + refereeStats[acc.id!] ? referee : acc)
  );
};

export const generateRefereesForMatch = (
  referees: Referee[],
  refereeStats: { [id: string]: number },
  match: Match,
  currentInvites: MatchInvite[],
  refereeTimes: {[id: string]: number[]},
  settings: Club["settings"]
) => {
  const requiredReferees = match.referees || settings.referees || 1;

  // Process invites: Accepted referees stay, old invites become null unless they are re-used
  let newInvites: MatchInvite[] = currentInvites.map(invite => ({
    ...invite,
    status: invite.status === "invite" || invite.status === "declined" ? null : invite.status
  }));

  let newInvitesLength = newInvites.filter(invite => invite.status !== null).length;

  newInvites.forEach(invite => {
    if (invite.status !== null) {
      refereeStats[invite.refereeId!] += 1; // Keep track of accepted referees' match count
    }
  });

  const assignedReferees = new Set(newInvites.filter(invite => invite.status !== null).map(invite => invite.refereeId));

  // Filter eligible referees (excluding already assigned ones)
  const availableReferees = referees
    .filter(referee => (referee.permissions || []).includes(match.periodLength || 0))
    .filter(ref => !assignedReferees.has(ref.id)) // Ensure no duplicates
    .filter(ref => {
      // Check if referee has a match within 3 hours (including newly assigned ones)
      const matchTimes = refereeTimes[ref.id!] || [];
      const currentMatchTime = new Date(match.time).getTime();
      return !matchTimes.some(time => Math.abs(time - currentMatchTime) < THREE_HOURS_MS);
    })
    .sort((a, b) => (refereeStats[a.id!] || 0) - (refereeStats[b.id!] || 0)); // Prioritize less-used refs

  // Assign referees until we reach the required number
  while (newInvitesLength < requiredReferees && availableReferees.length > 0) {
    const referee = availableReferees.shift();
    if (referee) {
      refereeStats[referee.id!] += 1;
      assignedReferees.add(referee.id!);
      newInvites.push({
        refereeId: referee.id!,
        clubId: match.clubId!,
        matchId: match.id,
        name: referee.name,
        phoneNumber: referee.phoneNumber,
        status: "invite",
      });
      newInvitesLength++;
    }
  }
  newInvites.forEach(inv => {
    const time = new Date(match.time).getTime();
    if (refereeTimes[inv.refereeId]) {
      refereeTimes[inv.refereeId].push(time)
    } else {
      refereeTimes[inv.refereeId] = [time]
    }
  })
  return newInvites;
};
export const generateExampleMatches = (
  matches: Match[],
  referees: Referee[],
  matchInvitations: { [matchId: string]: MatchInvite[] },
  settings: Club["settings"]
) => {
  const refereeStats: { [id: string]: number } = Object.fromEntries(referees.map(ref => [ref.id!, ref.matches || 0]));
  const refereeTimes: {[id: string] : number[]} = {};
  const newExampleMatches: { [matchId: string]: Match } = {};
  const newInviteExamples: { [matchId: string]: MatchInvite[] } = {};

  matches.sort(() => Math.random() - 0.5).forEach(match => {
    const compensation = generateCompensation(settings!, match.periodLength)

    if (match.id && compensation) {
      const currentInvites = matchInvitations[match.id!] || [];
      match.referees = settings.referees || 1;
      // Generate referees for the match
      const updatedInvites = generateRefereesForMatch(referees, refereeStats, match, currentInvites, refereeTimes, settings);
  
      newInviteExamples[match.id] = updatedInvites;
      newExampleMatches[match.id] = {
        ...match,
        compensation
      };
    }

  });

  return { newExampleMatches, newInviteExamples };
};