import { useState, useEffect, useCallback, useRef } from 'react';
import {Invitation, paths, Referee, refereeStatus } from '@monorepo/shared';
import { useReferees } from 'contexts/RefereesContext';
import { useInvitations } from 'contexts/InvitationsContext';
import { useMatches } from 'contexts/MatchesContext';
import { writeBatch, doc } from "firebase/firestore";
import { db } from 'config/firebase/firebase';
import { useClub } from 'contexts/ClubContext';
import { useUserData } from './useUserData';

type RelevantStatuses = "invite" | "accepted" | "done" | "paid";

export const useMatchesCount = () => {

  const {acceptedReferees} = useUserData();
  const {matchInvitations} = useMatches();
  const [matchesCount, setMatchesCount] = useState<{[id: string]: Record<RelevantStatuses, number>}>({});

  useEffect(() => {
    if (!matchInvitations ) {
      return;
    }
    const matchesCountTmp: {[id: string]: Record<RelevantStatuses, number>} = {}; 
    Object.values(matchInvitations)
      .flat()
      .forEach(inv => {
        if (!matchesCountTmp[inv.refereeId]) {
          matchesCountTmp[inv.refereeId] = {"invite": 0, "accepted": 0, "done": 0, "paid": 0}
        }
        if (inv.status && ["invite", "accepted", "done", "paid"].includes(inv.status)) {
          matchesCountTmp[inv.refereeId][inv.status as RelevantStatuses] += 1;
        }
      })

      setMatchesCount(matchesCountTmp)
  }, [matchInvitations])

  const lastSyncRef = useRef<number>(0);

  useEffect(() => {
    if (!acceptedReferees || acceptedReferees.length <= 0 || !matchesCount) {
      return
    }
    const now = Date.now();
  
    // Always run the first update immediately then first 5 min later
    if (lastSyncRef.current === 0 || now - lastSyncRef.current > 300000) { 
      syncMatchesCount(acceptedReferees, matchesCount);
      lastSyncRef.current = now;
    }
  }, [acceptedReferees, matchesCount]);

  return matchesCount
};

async function syncMatchesCount(referees: (Referee & Invitation)[], matchesCountTmp: {[id: string]: Record<RelevantStatuses, number>}) {
  try {
    const batch = writeBatch(db);
    console.log(referees)
    referees.forEach(inv => {
      const count = (matchesCountTmp[inv.id]?.done || 0) + (matchesCountTmp[inv.id]?.paid || 0);
      const refereeRef = doc(db, paths.invitations, `${inv.clubId}_${inv.userId}`);
      if (count !== inv.matches && inv.id) {
        batch.update(refereeRef, { matches: count });
      }
      const countComing = (matchesCountTmp[inv.id]?.invite || 0) + (matchesCountTmp[inv.id]?.accepted || 0);
      if (countComing !== inv.comingMatches && inv.id) {
        batch.update(refereeRef, { comingMatches: countComing });
      }
    });
  
    await batch.commit(); 
  } catch (error) {
    console.error("Error syncing matches: ", error);
  }
}