import { Box, Card, CardContent, Grid, Typography, Avatar, IconButton, Stack, Paper, Grid2,  InputAdornment, InputBase} from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import SavingsIcon from "@mui/icons-material/Savings";
import BarChartIcon from "@mui/icons-material/BarChart";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { OpenSideBarButton } from "shared/components/OpenSideBarButton";
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import { BarChart, LineChart } from "@mui/x-charts";
import { useStatistics } from "features/users/hooks/statistics";
import dayjs, { Dayjs } from "dayjs";
import { Colors, emptyMatch, Match } from "@monorepo/shared";
import { useNavigate } from "react-router-dom";
import { useMatches } from "contexts/MatchesContext";
import { ScheduleTable } from "features/matches/components/ScheduleTable";
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useEffect, useRef, useState } from "react";
import { matchWarning } from "features/matches/utils/matchWarning";
import { MatchEditor } from "features/matches/components/MatchEditor";
import { useInvitations } from "contexts/InvitationsContext";
import { Firestore, Timestamp } from "firebase/firestore";
import { useUser } from "contexts/UserContext";
import { Image, InsertDriveFile } from "@mui/icons-material";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Chat } from "features/users/components/chat";

const now = dayjs();
const year = now.year();

const isFirstSeason = now.month() < 6; 

const startDate = isFirstSeason 
  ? dayjs(`${year}-01-01`)  
  : dayjs(`${year}-07-01`);

const endDate = isFirstSeason
  ? dayjs(`${year}-06-30`)  
  : dayjs(`${year}-12-31`); 


const Overview = () => {
  const navigate = useNavigate();


  
  const {matches, matchInvitations} = useMatches();
  const {invitations} = useInvitations();
  const {user} = useUser();
  const [warningMatches, setWarningMatches] = useState<Match[]>([]);

  useEffect(() => {
    setWarningMatches(matches.filter(m => matchWarning(m, matchInvitations)));
  }, [matchInvitations, matches])

  const {monthlyPayments, monthlyUnpaid, monthlyPredicted, months, paymentInfo} = useStatistics(startDate, endDate);

  const stats = [
    { title: "Obetalt", value: paymentInfo.unpaid, valueText: "kr", icon: <AttachMoneyRoundedIcon />, color: "error.main", onClick: () => navigate("/home/payments/unpaid") },
    { title: "Matcher utan domare", value: warningMatches.length, valueText: " matcher", icon: <CloseIcon />, color: "error.main", onClick: () => navigate("/home/schedule/coming") },
    { title: "Nya förfrågningar", value: invitations.filter(inv => inv.role === "referee" && inv.status === "invited").length, valueText: " domare", icon: <PersonIcon />, color: "primary.main", onClick: () => navigate("/home/users/referees") },
  ];
  
  const [selectedMatch, setSelectedMatch] = useState<Match>();

  return (
    <Stack p={2} spacing={3} sx={{overflow: "auto" }}>
      <Stack direction="row" alignItems="center">
        <OpenSideBarButton inverted />
        <Typography variant="h5" fontWeight="bold" color="white">
          Överblick {isFirstSeason ? "vårsäsongen" : "höstsäsongen"} {year}
        </Typography>
      </Stack>

      {/* Main Content */}
      <Stack direction={{ xs: "column", md: "row" }} spacing={3} maxHeight={{md: 500, xs: 1000}} >
        {/* Left Side - Stats & Chart */}
        <Stack spacing={3} sx={{ flex: 2, minWidth: 0 }}>
          {/* Stats Cards */}
          <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
            {stats.filter(s => s.value !== 0).slice(0,3).map((stat, index) => (
              <Card key={index} sx={{ display: "flex", alignItems: "center", p: 2, borderRadius: 2, boxShadow: 3, flex: 1, cursor: "pointer" }}  onClick={stat.onClick}>
                <Avatar sx={{ bgcolor: stat.color, mr: 2 }}>{stat.icon}</Avatar>
                <Stack>
                  <Typography variant="subtitle2" color="textSecondary">
                    {stat.title}
                  </Typography>
                  <Typography variant="h6" fontWeight="bold">
                    {stat.value}{stat.valueText}
                  </Typography>
                </Stack>
              </Card>
            ))}
          </Stack>

          {/* Line Chart */}
          <Card sx={{ p: 2, flex: 1, borderRadius: 2, boxShadow: 3, cursor: "pointer"}} onClick={() => navigate("/home/users/statistics")} >
            <Typography variant="h6" fontWeight="bold">Utbetalningar</Typography>
            <BarChart
              xAxis={[{ data: months || [], scaleType: "band" }]}
              series={[
                { data: monthlyPredicted, color: "gray", label: `Förväntat belopp: ${paymentInfo.predicted}kr` },
                { data: monthlyPayments, color: Colors.darkBlue, label: `Betalat belopp: ${paymentInfo.paid}kr` },
                { data: monthlyUnpaid, color: Colors.black, label: `Obetalt belopp: ${paymentInfo.unpaid}kr` },
              ]}
              height={300}

            />
          </Card>
        </Stack>

        {/* Right Side - Chat */}
        <Card sx={{ flex: 1, p: 2,  borderRadius: 2, boxShadow: 3, display: "flex", flexDirection: "column", minHeight: 0 }}>
          <Chat />
        </Card>
      </Stack>

      {/* Bottom Section - Schedule Table */}
      <Card sx={{ p: 2, flex: 1, borderRadius: 2, boxShadow: 3, minHeight: "fit-content" }}>
        <Typography variant="h6" fontWeight="bold">Relevanta matcher</Typography>
        <MatchEditor open={selectedMatch !== undefined} match={selectedMatch || emptyMatch()} onClose={() => setSelectedMatch(undefined)}/>
        <ScheduleTable matches={[...warningMatches]} matchInvitations={matchInvitations} onSelectMatch={(match) => setSelectedMatch(match)} />
      </Card>
    </Stack>
  );
};

export default Overview;