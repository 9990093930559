import React, { useEffect } from "react";
import { Colors, filterComing, Match, MatchInvite, paths, Referee, refereeStatus } from "@monorepo/shared";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fade, FormControl, FormControlLabel, FormGroup, Grid2, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Modal, Paper, Select, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/sv'
import dayjs from 'dayjs';
import { TimePicker } from "@mui/x-date-pickers";
import { collection, deleteDoc, doc,  setDoc, Timestamp} from "firebase/firestore";
import { nextStatusTitle, statusToTitle } from "../utils/refereeStatus";
import { refereeIcon } from "./DisplayReferee";
import { db } from "config/firebase/firebase";
import { useAppContext } from "contexts/AppContext";
import { useClub } from "contexts/ClubContext";
import { useMatches } from "contexts/MatchesContext";
import { useReferees } from "contexts/RefereesContext";
import { MatchInfoEditor } from "./MatchInfoEditor";
import { removeMatch, saveMatch } from "../utils/saveMatch";
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useInvitations } from "contexts/InvitationsContext";

export const style = {
  width: { xs: "100%", sm: "90%", md: "80%", lg: "70%" },
  position: 'absolute' as 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -30%)',
  boxShadow: 24,
  padding: "3%",  
};

// Invites only for examples to prevent firebase save 
export const MatchEditor = ({ match, open, onClose, invites, updateExampleMatch}: {match: Match, open: boolean, onClose: () => void, invites?: MatchInvite[], updateExampleMatch?: (updatedMatch: Match, updatedInvites: MatchInvite[]) => void}) => {
  const [updatedMatch, setUpdatedMatch] = React.useState(match);
  const [updatedInvites, setUpdatedInvites] = React.useState<{[refereeId: string] : MatchInvite}>({});
  const [openDelete, setOpenDelete] = React.useState(false);
  const [refereeSearch, setRefereeSearch] = React.useState("");

  const {setStatusMessage, setOpenStatusMessage, setStatusMessageType} = useAppContext();
  const {club} = useClub();
  const {acceptedReferees, referees} = useReferees();
  const {invitations} = useInvitations();
  
  const {matchInvitations} = useMatches();


  React.useEffect(() => {
    setUpdatedMatch({...match});
    setOpenDelete(false);
  }, [match, setUpdatedMatch])

  React.useEffect(() => {
    if (!match.id || !match) {
      return
    }
    setUpdatedInvites((invites || matchInvitations[match.id] || []).reduce((acc, invite) => {
      acc[invite.refereeId] = invite;
      return acc
    }, {} as {[refereeId: string] : MatchInvite}))
  }, [invites, match, match.id, matchInvitations])

  const handleInputChange = (value: string | number | boolean | {[k: string]: MatchInvite}, field: keyof Match) => {
    setUpdatedMatch(prev => ({...prev!, [field] : value}))
  };

  const updateReferee = (referee: Referee, status: refereeStatus | null) => {
    if (!referee.id || !club?.id) return
     
    const statusIndices: (refereeStatus)[] = filterComing(updatedMatch) ? [null, 'invite', 'accepted'] : [null, 'done'];
    const statusIndex = statusIndices.indexOf(status ? status : null) + 1;
    const nextStatus = statusIndices[statusIndex % statusIndices.length]

    const newInvite: MatchInvite = {
      name: referee.name,
      refereeId: referee.id,
      clubId: club?.id,
      matchId: updatedMatch.id,
      phoneNumber: referee.phoneNumber,
      status: nextStatus,
    }
    if (nextStatus === "accepted") {
      newInvite.acceptedAt = Timestamp.fromDate(new Date());
    }
    setUpdatedInvites({...updatedInvites, [referee.id]: newInvite});
  };
  const toggleEveryone = () => {
    if (!club?.id) return
    setUpdatedMatch({...updatedMatch, everyone: !updatedMatch.everyone});
  }
  
  const trySaveMatch = async () => {
    if (!club?.id) return;
    // Handle examples 
    if (updateExampleMatch) {
      // Call a new prop function to update example state in parent
      updateExampleMatch(updatedMatch, Object.values(updatedInvites));
      onCloseModal();
      return;
    }
    saveMatch(updatedMatch, setStatusMessage, setStatusMessageType, setOpenStatusMessage, onCloseModal, updatedInvites, club)
  }

  const onCloseModal = () => {
    setUpdatedInvites({});
    onClose();
  }
  
  return (
    <Modal disableAutoFocus={true} closeAfterTransition open={open} onClose={onCloseModal}>
        <Fade in={open}>
          <Paper sx={style} >
            <Stack sx={{flexDirection: {xs: "column", md: "row"}}} width={"100%"}>
              <Box>
                <MatchInfoEditor handleInputChange={handleInputChange} match={updatedMatch}/>       
                <Stack alignSelf={"center"} justifyContent={"space-between"} spacing={3} direction={"row"} marginTop={2}>
                  <Dialog
                    open={openDelete}
                    onClose={() => setOpenDelete(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Radera Match"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Tar bort en match, går inte att ångra. 
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpenDelete(false)} autoFocus>Avbryt</Button>
                      <Button onClick={() => removeMatch(updatedMatch, setStatusMessage, setStatusMessageType, setOpenStatusMessage, onClose, matchInvitations, club)}> Radera </Button>
                    </DialogActions>
                  </Dialog>
                  <Button variant="contained" color="error" disabled={!updatedMatch.id} onClick={() => setOpenDelete(true)}>
                    Radera
                  </Button>
                  <Stack alignSelf={"center"} justifyContent={"center"} spacing={3} direction={"row"} margin={2}>
                    <Button variant="contained" onClick={onCloseModal}>
                      Avbryt
                    </Button>
                    <Button variant="contained" onClick={trySaveMatch}>
                      Spara
                    </Button>
                  </Stack>
                </Stack>
              </Box>
              <Divider orientation="vertical" flexItem sx={{margin: 2, bgcolor: Colors.primary}}/>
              <Box width="100%">
                <TextField variant="standard" label="Domare" fullWidth onChange={(e) => setRefereeSearch(e.target.value)} value={refereeSearch}></TextField>
                <Button
                  variant="text"
                  color={updatedMatch.everyone ? "success" : "inherit"}
                  onClick={toggleEveryone}
                  disabled={!club?.settings.matchTypes[updatedMatch.periodLength!]?.displayValue}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    textTransform: "none",
                    padding: "10px 16px",
                  }}
                >
                  <Tooltip 
                    placement="right" 
                    title={!updatedMatch.everyone ? `Klicka för att skicka inbjudan till alla ${club?.settings.matchTypes[updatedMatch.periodLength!]?.displayValue} domare` : "Klicka för att ta bort inbjudan"}
                  >
                    <Stack 
                      direction="row" 
                      alignItems="center" 
                      width="100%" 
                      justifyContent="space-between"
                    >
                      <Typography variant="body1">Alla</Typography>
                      {updatedMatch.everyone ? <GroupIcon /> : <GroupAddIcon />}
                    </Stack>
                  </Tooltip>
                </Button>
                <Divider />
                <List sx={{overflowY: "auto", maxHeight: {xs: "150px", md: "400px"}}}>
                  {acceptedReferees.filter(referee => referee.name.toLocaleLowerCase().includes(refereeSearch.toLocaleLowerCase())).map(referee => {
                    const defaultReferee = updatedInvites[referee.id!] || null;
                    return (
                      <ListItem
                        divider 
                        key={referee.id}
                        onPointerDown={() => updateReferee(referee, defaultReferee ? defaultReferee.status : null)} 
                      >
                        <Tooltip placement="right" title={"Klicka för att: " + nextStatusTitle(defaultReferee?.status || null, filterComing(match))} key={referee.name}>
                            <Stack direction={"row"} width={"100%"} sx={{justifyContent: "space-between", flexDirection: 1}} alignItems={"center"} >
                              <ListItemText>{referee?.name || ""}</ListItemText>
                              <ListItemIcon sx={{justifyContent: "flex-end"}}>{defaultReferee ? refereeIcon(defaultReferee.status, filterComing(updatedMatch)) : ""}</ListItemIcon>
                            </Stack>
                        </Tooltip>
                      </ListItem>
                    )
                  })} 
                </List>
              </Box>
            </Stack>
          </Paper>
        </Fade>
    </Modal>
  )
}