import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Box, Typography, Stack, IconButton, Toolbar, Divider, Link, Chip, Tooltip, Drawer, Tabs, List, Tab, ListItemButton, ListItem, ListItemText, ListItemIcon, LinearProgress, CircularProgress } from '@mui/material';
import { Club, Colors, emptyMatch, filterComing, filterPlayed, formatTime, Invitation, Match, MatchInvite, matchType, paths, Referee, refereeStatus, sortByDate } from '@monorepo/shared';
import { useOutletContext } from 'react-router-dom';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { collection, doc, setDoc, updateDoc, writeBatch } from 'firebase/firestore';
import { useAppContext } from 'contexts/AppContext';
import { useMatches } from 'contexts/MatchesContext';
import { MatchEditor } from 'features/matches/components/MatchEditor';
import { MatchEditorTrainer } from 'features/matches/components/MatchEditorTrainer';
import { useClub } from 'contexts/ClubContext';
import EditIcon from '@mui/icons-material/Edit';
import { OpenSideBarButton } from 'shared/components/OpenSideBarButton';


export const TrainerSchedule = () => {
  const {matches, matchInvitations} = useMatches();
  const {club} = useClub();
  const [coming, setComing] = useState<Match[]>([]);
  const {setTitle, setTabSettings, setTabs} = useAppContext();

  const [selectedMatch, setSelectedMatch] = useState<Match>();

  const formatMatches = React.useCallback(() => {
    const upcomingMatches = matches.filter(filterComing).sort(sortByDate);
  
    setComing(upcomingMatches);
  }, [matches])

  useEffect(() => {
    setTitle("Kommande Matcher");
    setTabs(
    <List>
        <ListItem>
          <ListItemButton component="button" onClick={() => setSelectedMatch(emptyMatch())}>
            <ListItemIcon>
              <AddRoundedIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Ny Match / Poolspel" sx={{ textAlign: 'center', color: Colors.white }} />
          </ListItemButton>
        </ListItem>
      </List>
    );
    setTabSettings(null);
  }, [setTitle, setTabSettings, setTabs]);

  useEffect(() => {
    formatMatches();
  }, [matches, formatMatches]);

  const sortedInvites = (invites: MatchInvite[]) => (
    Object.values(invites || {}).sort((a, b) => a.name > b.name ? 1 : -1).filter(invite => invite.status === "accepted")
  )

  const displayInviteField = (invites: MatchInvite[], field: "name" | "phoneNumber") => {
    if (sortedInvites(invites).length <= 0) {
      return null
    }
      return (
      <Stack>
        {sortedInvites(invites).map((invite, i) => (
          <Typography key={i}>
            {invite[field]}
          </Typography>      
        ))}
      </Stack>
    )
  }

  const canEdit = (match: Match) => (!match.id?.includes(club?.id || ""))

  return (
    <>
      <MatchEditorTrainer open={selectedMatch !== undefined} match={selectedMatch || emptyMatch()} onClose={() => setSelectedMatch(undefined)}/>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ whiteSpace: "nowrap", p: 2, width: "100%"}}>
        <Stack direction={"row"} alignItems={"center"}  sx={{ whiteSpace: "nowrap"}}>
          <OpenSideBarButton />
          <Typography variant="h6" fontWeight="bold" >
            {coming.length} Kommande matcher
          </Typography>
        </Stack>
        <Tooltip title="Lägg till match">
          <Button variant="contained" color="primary" onClick={() => setSelectedMatch(emptyMatch())}>
            <AddRoundedIcon />
          </Button>
        </Tooltip>
      </Stack>
      <Divider />
      <TableContainer sx={{ height: "100%", maxHeight: "100%", overflowY: "auto" }} component={Paper}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {["", "Tid", "Plats", "Lag", "Division", "Domare", "Telefon", ""].map(title => (
                <TableCell key={title} sx={{ whiteSpace: 'nowrap'}}><span style={{fontWeight: "bolder"}}>{title}</span></TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody >
            {coming.map((match, i) => (
              <TableRow  key={i} hover={canEdit(match)} onClick={() => {canEdit(match) && setSelectedMatch(match) }} sx={{ cursor: canEdit(match) ? 'pointer' : 'auto'}}>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{i}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{formatTime(match.time)}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{match.place}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  <Stack direction="column">
                    <span>{match.home}</span>
                    <span>{match.away}</span>
                  </Stack>
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{match.division}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{displayInviteField(matchInvitations[match.id!], "name") || (match.everyone && "Inbjudan skickad")}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{displayInviteField(matchInvitations[match.id!], "phoneNumber")}</TableCell>
                <TableCell align='right' sx={{ whiteSpace: 'nowrap' }}>{canEdit(match) ? <IconButton><EditIcon/></IconButton> : ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};