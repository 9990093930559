import * as React from "react";
import { Stack, Typography, Link, TextField, Button, Box, Autocomplete, Select, MenuItem, FormControl, InputLabel, CircularProgress, LinearProgress, FormGroup, FormControlLabel, Checkbox, Modal, emphasize, IconButton, Alert, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Colors, Invitation, paths, User as ClubUser, Club } from "@monorepo/shared";
import { getDistricts, getClubs } from "@monorepo/shared";
import {createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, User} from "firebase/auth"
import { auth, db } from "../../../config/firebase/firebase";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import CloseIcon from '@mui/icons-material/Close';
import { FirebaseError } from "firebase/app";
import { useNavigate } from "react-router-dom";
import { emptyUser, resetPassword, signInUser } from "../utils/user";
import { AuthDrawer } from "./Drawer";


export const SignIn = () => {

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [showResetPassword, setShowResetPassword] = React.useState(false);

  const [errorText, setErrorText] = React.useState("");

  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const handleClick = async () => {
    if (showResetPassword) {
      await resetPassword(email, setErrorText);
    } else {
      const role = await signInUser(email, password, setErrorText);
      if (role === "responsible") {
        navigate("/home/");
      } else if (role === "referee") {
        await signOut(auth);
        setOpen(true);
      } else {
        
        await signOut(auth);
        navigate("/");
      }

    }
  }

  return (
    <AuthDrawer signIn>
      <Stack role="presentation">
      <Dialog
        open={open}
        onClose={() => {setOpen(false); navigate("/")}}
        aria-labelledby="wrong-account-dialog-title"
        aria-describedby="wrong-account-dialog-description"
      >
        <DialogTitle id="wrong-account-dialog-title">
          {"Fel typ av konto"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Du har försökt logga in med ett domarkonto. Notera att konton på hemsidan och app är olika. 
            För att använda hemsidan behöver du skapa ett nytt konto. Vid frågor och funderingar kontakta oss! 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setOpen(false); navigate("/")}}>Ok</Button>
        </DialogActions>
      </Dialog>
        <TextField  id="mail" label="Mail" variant="standard" value={email} onChange={e => setEmail(e.target.value)}/>
        {!showResetPassword && <TextField 
          fullWidth type='password' 
          onKeyDown={(e) => {e.key === "Enter" && signInUser(email, password, setErrorText)}} 
          value={password} id="password" label="Lösenord" variant="standard" 
          onChange={e => setPassword(e.target.value)}
        />}
        <Button onClick={() => setShowResetPassword(!showResetPassword)}>{showResetPassword ? "Logga In" : "Glömt lösenord?"}</Button>
        {errorText !== "" && 
          <Alert severity="error" >
            {errorText}
          </Alert>
        }
        <Button onClick={handleClick} variant="contained" >{showResetPassword ? "Återställ" : "Logga in"}</Button>
      </Stack>      
    </AuthDrawer>
  )
}