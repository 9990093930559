import React, { useEffect, useState,  } from 'react';
import {  Box, Button, Chip, Divider, IconButton, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Tooltip, Typography } from '@mui/material';
import {  Colors, emptyMatch, Match, MatchInvite, refereeStatus,  } from '@monorepo/shared';
import { useMatchData } from 'features/matches/hooks/useMatchData';
import { useAppContext } from 'contexts/AppContext';
import { ScheduleTable } from 'features/matches/components/ScheduleTable';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useMatches } from 'contexts/MatchesContext';
import { useClub } from 'contexts/ClubContext';
import { MatchEditor } from './MatchEditor';
import { statusColor, statusToTitle } from '../utils/refereeStatus';
import { groupByRefereeStatus } from '../utils/groupByRefereeStatus';
import { OpenSideBarButton } from 'shared/components/OpenSideBarButton';
import { DisplayRefereeStatus } from './DisplayRefereeStatus';
import { GridSearchIcon } from '@mui/x-data-grid';

export const Coming = () => {
  const {coming} = useMatchData();
  const {matchInvitations} = useMatches();
  const {setTitle, setTabSettings} = useAppContext();
  const [selectedMatch, setSelectedMatch] = useState<Match>();
  const navigate = useNavigate();

  const [divisionSearch, setDivisionSearch] = useState("");

  const {club} = useClub();

  const displayMatches = coming.filter(match => match.division.toLocaleLowerCase().includes(divisionSearch.toLocaleLowerCase()));

  useEffect(() => {
    setTitle("Kommande matcher");
    setTabSettings(
      <List>
        <ListItem>
          <ListItemButton component="button" onClick={() => setSelectedMatch(emptyMatch())}>
            <ListItemIcon>
              <AddRoundedIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Ny Match" sx={{ textAlign: 'center', color: Colors.white }} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component="button" onClick={() => navigate("/home/schedule/examples")}>
            <ListItemIcon>
              <AutorenewIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Generera exempel" sx={{ textAlign: "center", color: Colors.white }} />
          </ListItemButton>
        </ListItem>
      </List>
    );
  }, [club?.settings.referees, navigate, setSelectedMatch, setTabSettings, setTitle])

  return (
    <>
    <Stack direction="column">
      <Stack direction={"row"} alignItems={"center"} sx={{ whiteSpace: "nowrap", p: 1, pt: 2, pb:0  , pl: 2}}>
        <OpenSideBarButton />
        <Typography variant="h6" fontWeight="bold" >
          {coming.length} Kommande matcher
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="end" p={1} pt={0} pl={2}>
        {/* Referee Status Chips */}
        <DisplayRefereeStatus matches={displayMatches} matchInvitations={matchInvitations} coming={true} /> 

        {/* Buttons */}
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Paper
            component="form"
            sx={{height: 36, display: 'flex', alignItems: 'center',  }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Sök på division"
              inputProps={{ 'aria-label': 'sök' }}
              value={divisionSearch}
              onChange={e => setDivisionSearch(e.target.value)}
            />
            <IconButton type="button" aria-label="search">
              <GridSearchIcon />
            </IconButton>
          </Paper>   
          <Button variant="contained" color="primary"  onClick={() => navigate("/home/schedule/examples")}>
            Generera exempel
          </Button>
          <Divider orientation="vertical" flexItem  />
          <Tooltip title="Lägg till match">
            <Button variant="contained" color="primary" onClick={() => setSelectedMatch(emptyMatch())}>
              <AddRoundedIcon />
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
      <Divider />
      <MatchEditor open={selectedMatch !== undefined} match={selectedMatch || emptyMatch()} onClose={() => setSelectedMatch(undefined)}/>
      <ScheduleTable matches={displayMatches} matchInvitations={matchInvitations} onSelectMatch={(match) => setSelectedMatch(match)}/>
    </>
  );
};
