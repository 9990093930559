import { Club, Match, MatchInvite, paths } from "@monorepo/shared";
import { db } from "config/firebase/firebase";
import { collection, deleteDoc, doc, writeBatch } from "firebase/firestore";

export const saveExamples = async (clubId: string, examples: Match[], exampleInvites: {[matchId: string]: MatchInvite[]}, invites: {[matchId: string]: MatchInvite[]}) => {

  try {
    const matchesRef = collection(db, paths.matches);
  
    const batch = writeBatch(db);  
    examples.forEach(match => {
      if (match) {
        const matchRef = match.id ? doc(matchesRef, match.id.toString()) : doc(matchesRef);
        
        batch.set(matchRef, match, { merge: true });
  
        (exampleInvites[matchRef.id] || []).forEach(async invite => {
          const inviteRef = doc(db, paths.matchInvites, `${matchRef.id.toString()}_${invite.refereeId}`)
  
          if (invite.status === null) {
            if (match.id && invites[match.id]?.find(inv => inv.refereeId === invite.refereeId)) {
              batch.delete(inviteRef)
            }
          } else {
           batch.set(inviteRef, invite,{ merge: true });
          }
  
        })
      }
    });
  
    await batch.commit();
  } catch (error) {
    console.error("Error saving examples: ", error);
  }
};