import React, { useEffect, useState } from "react";
import { calculateSize, Club, Colors, filterComing, getClubs, getDistricts, Invitation, paths, UserRole, } from "@monorepo/shared";
import { Alert, Autocomplete, Box, Button, Fade, FormControl, IconButton, InputLabel, LinearProgress,Link,MenuItem, Modal, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/sv'
import { TimePicker } from "@mui/x-date-pickers";
import { collection, deleteDoc, doc, getDocs, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import CloseIcon from '@mui/icons-material/Close';

import { db } from "config/firebase/firebase";
import { createClubInFirestore, createInvitationInFirestore, defaultClubSettings } from "shared/utils/clubCreation";
import { useUser } from "contexts/UserContext";
import { useNavigate } from "react-router-dom";

export const ClubEditor = ({ onClose} : {onClose?: () => void}) => {

  const {user, userInvitations} = useUser();

  const [newClub, setNewClub] = useState(true);

  const [club, setClub] = useState<Club>({users: {}, id: "", clubName: "", clubType: "", settings: {matchTypes: {}}});
  const [role, setRole] = useState<UserRole>("trainer");

  const [district, setDistrict] = useState("");
  const [districts, setDistricts] = useState<{value: string, label: string}[]>([]);

  const [clubs, setClubs] = useState<{value: string, label: string}[]>([]);
  const [takenClubs, setTakenClubs] = useState<{value: string, label: string}[]>([])
  
  const [clubTaken, setClubTaken] = useState<boolean>(false);
  const [size, setSize] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    try {
      (async () => {
        const clubRef = collection(db, paths.clubs);
        const clubs = (await getDocs(clubRef)).docs
          .map(doc => ({value: doc.id, label: doc.data()["clubName"]}))
        setTakenClubs(clubs || [])
      })()
    } catch (error) {
      console.error("Error fetching taken clubs: ", error);
    }
  }, [userInvitations])

  useEffect(() => {
    (async () => {
      setDistricts(await getDistricts(club.clubType) || [])
    })()
  }, [club.clubType])
  
  useEffect(() => {
    if (district === "") return;
    (async () => {
      setClubs(await getClubs(district, club.clubType) || [])
    })()
  }, [district, club.clubType])

  useEffect(() => {
    if (club.id === "") return;
    setSize(""); 
    const isTaken = takenClubs.find(c => c.value === club.id) !== undefined;
    setClubTaken(isTaken);
    if (isTaken || (!newClub && role === "trainer")) return;
    (async () => {
      const clubSize = await calculateSize(parseInt(club.id), club.clubType);
      setSize(clubSize || "small");
    })();
  }, [club.id, club.clubType, takenClubs, role, newClub]);


  const handleClick = async () => {
    if (!user) {
      return
    }
    try {
      if (newClub) {
        club.settings = defaultClubSettings;
        club.users = {[user.id]: "responsible"}
  
        if (user.referral) {
          await setDoc(doc(db, "referrals", `${club.id}_${user.referral}`), {
            referralId: user.referral,
            clubUser: user.id,
            clubEmail: user.email,
            clubId: club.id,
            clubName: club.clubName,
          })
        }
  
        await createClubInFirestore(club, size);
      }
  
      await createInvitationInFirestore(user, club, newClub, role);
      if (newClub || user.activeClub === "") {
        const userRef = doc(db, paths.clubUsers, user.id);
        await updateDoc(userRef, {
          activeClub: club.id,
        })
      }
    } catch (error) {
      console.error("Error handling new club: ", error);
    }
  }


  // Update to use stripe products in future
  const calculatePrice = () => {
    switch (size) {
      case "big": 
        return 799
      case "medium":
        return 399
      default:
        return 249
    }
  }

  return (
    <Stack spacing={2} padding={1} maxHeight={"100%"} overflow={"scroll"}>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant="h5"><span>{newClub ? "Skapa ny klubb" : "Gå med i existerande klubb"}</span></Typography>
        {onclose && <IconButton onClick={onClose}><CloseIcon/></IconButton>}
      </Stack>
      {newClub ? 
        <Typography>
          Vill du hellre <Link onClick={() => setNewClub(!newClub)}>gå med i en exsisterande klubb?</Link>
        </Typography>
        :
        <Typography>
          Vill du hellre <Link onClick={() => setNewClub(!newClub)}>skapa en ny klubb?</Link>
        </Typography>
      }
      {!newClub && <FormControl fullWidth variant="outlined" sx={{marginTop: 2}}>
        <InputLabel id="sport-label">Välj en roll</InputLabel>
        <Select
          id="sport"
          value={role}
          variant="standard"
          label="Välj en sport"
          labelId="sport-label"
          onChange={(e) => setRole(e.target.value as UserRole)}
        >
          <MenuItem value={"trainer"}>Tränare</MenuItem>
          <MenuItem value={"responsible"}>Domaransvarig</MenuItem>
        </Select>
      </FormControl>}

      <Autocomplete
        disablePortal
        options={[{value: "soccer", label: "Fotboll"}, {value: "basket", label: "Basket"}, {value: "floorball", label: "Innebandy"}]} 
        onChange={(event, value) => setClub({...club, clubType: value?.value || ""})}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Välj en sport"  fullWidth />
        )}
        fullWidth
        sx={{ marginTop: 2 }}
      />
      <Autocomplete
        disablePortal
        disabled={club.clubType === ""}
        options={districts} 
        onChange={(event, value) => setDistrict(value?.value || "")}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Välj District"  fullWidth />
        )}
        fullWidth
        sx={{ marginTop: 2 }}
      />
      <Autocomplete
        disablePortal
        disabled={district === "" && (newClub || !club.clubType)}
        options={clubs} 
        onChange={(event, value) => {setClub({...club, id: value?.value || "", clubName: value?.label || ""})}}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Välj Club" fullWidth />
        )}
        fullWidth
        sx={{ marginTop: 2 }}
      />
      {club.id !== "" && newClub && 
        (!clubTaken ?
        <Stack direction={"row"} width="100" spacing={2} justifyContent={"center"} alignItems={"center"} padding={2}>
          <Typography>
            Pris: 
          </Typography>
          {size === "" ? <LinearProgress sx={{ width: 40}} /> : <span>{calculatePrice()}kr</span>}
        </Stack> :
        <Stack alignItems={"center"} justifyContent={"center"} width="100%">
          <Button onClick={() => setNewClub(false)}>
            Klubb är redan registrerad, Byt till gå med. 
          </Button>
          <Typography >
            Om något är fel <Link onClick={() => navigate("/help")}>kontakta oss!</Link> 
          </Typography>
        </Stack>
        )
      }
      {!newClub && club.id !== "" && takenClubs.find(c => c.value === club.id) === undefined && (
        <Typography>
          Klubb inte registrerad hos Reffi
        </Typography>
      )}
      {!newClub && userInvitations.find(invite => invite.clubId === club.id) && (
        <Typography>
          Inbjudan redan skickad
        </Typography>
      )}
      <Button disabled={club.id === "" || (!newClub && (takenClubs.find(c => c.value === club.id) === undefined)) || !!userInvitations.find(invite => invite.clubId === club.id) || (newClub && !size)} onClick={handleClick} variant="contained">{newClub ? "Skapa klubb" : "Skicka inbjudan"}</Button>
    </Stack>
  )
}