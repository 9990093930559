import { Club, Match, MatchInvite, paths } from "@monorepo/shared";
import { db } from "config/firebase/firebase";
import { collection, deleteDoc, doc, setDoc } from "firebase/firestore";

export const saveMatch = async (
  updatedMatch: Match, 
  setStatusMessage: (text: string) => void, 
  setStatusMessageType: (type: "success" | "info" | "warning" | "error" | undefined) => void,
  setOpenStatusMessage: (open: boolean) => void,
  onClose: () => void,
  updatedInvites: {[id:string] : MatchInvite} = {},
  club?: Club, 
) => {
  if (!club?.id) return;
  try {
    const savedMatch = {...updatedMatch}
    let matchRef;
    if (!updatedMatch.id) {
      matchRef = doc(collection(db, paths.matches));
      savedMatch.id = matchRef.id;
      savedMatch.clubId = club?.id;
    } else {
      matchRef = doc(db, paths.matches, updatedMatch.id.toString());
    }
    if (new Date(savedMatch.time).getTime() < new Date().getTime()) {
      savedMatch.everyone = false;
    }
    await setDoc(matchRef, savedMatch);
    Object.values(updatedInvites).forEach(async invite => {
      if (!invite.matchId) {
        invite.matchId = savedMatch.id!;
      } 
      const inviteRef = doc(db, paths.matchInvites, `${invite.matchId.toString()}_${invite.refereeId}`)
      if (invite && invite.status === null) {
        try {
          await deleteDoc(inviteRef);
        } catch (error) {
          console.error("Document deletion failed or document already deleted", error);
        }
      } else {
        await setDoc(inviteRef, invite);
      }
    })
    setStatusMessage("Match sparad!");
  } catch {
    setStatusMessage("Misslyckades att spara match!");
    setStatusMessageType("error");
  } finally {
    onClose();
    setOpenStatusMessage(true);
  }
}

export const removeMatch = async (
  updatedMatch: Match, 
  setStatusMessage: (text: string) => void, 
  setStatusMessageType: (type: "success" | "info" | "warning" | "error" | undefined) => void,
  setOpenStatusMessage: (open: boolean) => void,
  onClose: () => void,
  matchInvitations:  {[matchId: string]: MatchInvite[]},
  club?: Club, 
) => {
    if (club?.id && updatedMatch.id) {
      try {
        const matchRef = doc(db, paths.matches, updatedMatch.id.toString());
        const savedMatch = {...updatedMatch}
        // delete after 5 days
        const date = new Date();
        date.setDate(date.getDate() + 5)
        savedMatch.deleteAt = date.toISOString();
        await setDoc(matchRef, savedMatch);
        (matchInvitations[updatedMatch.id] || []).forEach(async invite => {
          const inviteRef = doc(db, paths.matchInvites, `${invite.matchId!.toString()}_${invite.refereeId}`)
            try {
              await deleteDoc(inviteRef);
            } catch (error) {
              console.error("Document deletion failed or document already deleted", error);
            }
        });
        setStatusMessage("Match borttagen!");
      } catch {
        setStatusMessage("Misslyckades med att ta bort match!");
        setStatusMessageType("error");
      } finally {
        onClose();
        setOpenStatusMessage(true);
      }
    }
    onClose()
}
