import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Chip, CircularProgress, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Popover, Stack, Tooltip, Typography } from '@mui/material';
import {  Colors, emptyMatch, Match, MatchInvite, refereeStatus, sortByDate, } from '@monorepo/shared';
import { useMatchData } from 'features/matches/hooks/useMatchData';
import { useAppContext } from 'contexts/AppContext';
import { MatchEditor } from 'features/matches/components/MatchEditor';
import { ScheduleTable } from 'features/matches/components/ScheduleTable';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { generateExampleMatches } from '../utils/generateExamples';
import { saveExamples } from '../utils/saveExamples';
import { useClub } from 'contexts/ClubContext';
import { useReferees } from 'contexts/RefereesContext';
import { useMatches } from 'contexts/MatchesContext';
import { sortMatches } from '../utils/sortMatches';
import { LoadingWrapper } from 'shared/components/LoadingWrapper';
import { statusColor, statusToTitle } from '../utils/refereeStatus';
import { groupByRefereeStatus } from '../utils/groupByRefereeStatus';
import { OpenSideBarButton } from 'shared/components/OpenSideBarButton';
import { DisplayRefereeStatus } from './DisplayRefereeStatus';

export const Examples = () => {
  const { club } = useClub();
  const { referees } = useReferees();
  const { matchInvitations } = useMatches();
  const { coming } = useMatchData();

  const { setStatusMessage, setOpenStatusMessage, setStatusMessageType } = useAppContext();
  const navigate = useNavigate();

  const [exampleMatches, setExampleMatches] = useState<{ [matchId: string]: Match }>({});
  const [exampleInvites, setExampleInvites] = useState<{ [matchId: string]: MatchInvite[] }>({});
  const [selectedMatch, setSelectedMatch] = useState<Match | undefined>();
  const [loadingSave, setLoadingSave] = useState(false);
  const [displayMatches, setDisplayMatches] = useState<Match[]>([]);
  const [displayInvites, setDisplayInvites] = useState<{[k: string]: MatchInvite[]}>({});

  useEffect(() => {
    if (!Object.keys(exampleInvites).length) return
    const newDisplayInvites = Object.fromEntries(
      displayMatches.map(match => [
        match.id,
        exampleInvites[match.id!] || matchInvitations[match.id!],
      ])
    );
  
    console.log("Updated displayInvites:", newDisplayInvites);
    setDisplayInvites(newDisplayInvites);
  }, [displayMatches, exampleInvites, matchInvitations]);
 
  useEffect(() => {
    setDisplayMatches(coming.map(match => exampleMatches[match.id!] || match).sort(sortMatches(sortByDate)));
  }, [coming, exampleMatches])

  useEffect(() => {
    if (!club || !referees.length || !matchInvitations || !coming.length) return;

    console.log("Generating example matches...");

    const { newExampleMatches, newInviteExamples } = generateExampleMatches(coming, referees, matchInvitations, club.settings!);
    setExampleMatches(newExampleMatches);
    setExampleInvites(newInviteExamples);
  }, [club, coming, referees, matchInvitations]);

  const regenerateExamples = useCallback(() => {
    if (!club) return;
  
    const userEditedMatches = Object.values(exampleMatches); 
    const userEditedInvites = Object.fromEntries(Object.entries(exampleInvites)
      .map(([matchId, invites]) => [matchId, invites.filter(inv => inv.status !== "invite" && inv.status !== "declined")]))
    const { newExampleMatches, newInviteExamples } = generateExampleMatches(
      userEditedMatches.length > 0 ? userEditedMatches : coming, 
      referees,
      Object.keys(userEditedInvites).length > 0 ? userEditedInvites : matchInvitations,
      club.settings!,
    );
  
    setExampleMatches(newExampleMatches); 
    setExampleInvites(newInviteExamples);
  }, [club, exampleMatches, coming, referees, exampleInvites, matchInvitations]);
  

  const acceptExamples = useCallback(async () => {
    const examples = Object.values(exampleMatches);
    if (!club || examples.length === 0) return;

    try {
      setLoadingSave(true);
      await saveExamples(club.id!, examples, exampleInvites, matchInvitations);
      setStatusMessage("Exempel sparade!");
    } catch (error) {
      setStatusMessage("Misslyckades med att spara exempel");
      setStatusMessageType("error");
      console.error("Error saving matches:", error);
    } finally {
      setOpenStatusMessage(true);
      setLoadingSave(false);
      navigate("/home/schedule/coming");
    }
  }, [exampleMatches, club, exampleInvites, matchInvitations, setStatusMessage, setStatusMessageType, setOpenStatusMessage, navigate]);

  const updateExampleMatch = (updatedMatch: Match, updatedInvites: MatchInvite[]) => {
    setExampleMatches(prev => ({ ...prev, [updatedMatch.id!]: updatedMatch }));
    setExampleInvites(prev => ({ ...prev, [updatedMatch.id!]: updatedInvites }));
  };


  return (
    <>
      <Stack direction="column">
        <Stack direction={"row"} alignItems={"center"} sx={{ whiteSpace: "nowrap", p: 1, pt: 2, pb:0, pl: 2}}>
          <OpenSideBarButton />
          <Typography variant="h6" fontWeight="bold" >
            Generera Exempel
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" alignItems="end" p={1} pt={0} pl={2}>
          {/* Referee Status Chips */}
          <DisplayRefereeStatus matches={displayMatches} matchInvitations={displayInvites} coming={true} /> 
          {/* Buttons */}
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Button variant="contained" color="secondary" sx={{ color: Colors.primary }} onClick={() => navigate("/home/schedule/coming")}>
              Avbryt
            </Button>
            <Button variant="contained" color="secondary" sx={{ color: Colors.primary }} onClick={acceptExamples}>
              Spara
            </Button>
            <Button variant="contained" color="primary" onClick={regenerateExamples}>
              Generera exempel igen
            </Button>
            <Divider orientation="vertical" flexItem  />
            <Tooltip title="Lägg till match">
              <Button variant="contained" color="primary" onClick={() => setSelectedMatch(emptyMatch())}>
                <AddRoundedIcon />
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <MatchEditor 
        open={selectedMatch !== undefined} 
        match={selectedMatch || emptyMatch()} 
        onClose={() => setSelectedMatch(undefined)} 
        invites={displayInvites[selectedMatch?.id!]} 
        updateExampleMatch={updateExampleMatch} />
      <ScheduleTable matches={displayMatches} matchInvitations={displayInvites} onSelectMatch={(match) => setSelectedMatch(match)}/>
    </>
  );
};