import { Box, Chip, Tooltip } from "@mui/material"
import { statusColor } from "../utils/refereeStatus"
import { Match, MatchInvite, refereeStatus } from "@monorepo/shared"
import { groupByRefereeStatus } from "../utils/groupByRefereeStatus"

export const statusToTitle = (status: refereeStatus, tab: boolean) => {
  switch (status) {
    case "invite" : return "Inbjudna Matcher";
    case "accepted": return tab ? "Accepterade Matcher" : "Ej Attesterade Matcher";
    case "done": return "Attesterade Matcher";
    case "paid": return "Betalda Matcher";
    case "declined": return "Nekada Matcher";
    case "withdrawn": return "Tillbakadragna Matcher";
  }
}

export const DisplayRefereeStatus = ({matches, matchInvitations, coming}:{matches: Match[], matchInvitations: {[matchId: string]: MatchInvite[];}, coming: boolean}) => {

  return (        
  <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
    {Object.entries(groupByRefereeStatus(matches, matchInvitations)).map(([status, matches]) => (
      <Tooltip title={statusToTitle(status as refereeStatus, coming)} key={status}>
        <Chip 
          sx={{ opacity: 0.8, px: 1 }} 
          color={statusColor(status as refereeStatus, coming)} 
          size="small"  
          label={`${matches}`} 
        />
      </Tooltip>
    ))}
  </Box>
  )
}