import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import {  Colors, Invitation, Referee } from "@monorepo/shared";
import { UserTable } from "features/users/components/UserTable";
import { EditReferee } from "features/users/components/EditReferee";
import { useAppContext } from "contexts/AppContext";
import { useUserData } from "../hooks/useUserData";
import { useClub } from "contexts/ClubContext";
import SearchIcon from '@mui/icons-material/Search';
import { OpenSideBarButton } from "shared/components/OpenSideBarButton";


const trainerColumns = ["Namn", "Roll", "Telefon", "Mail"];

export const Trainers = () => {
  const { club } = useClub();
  const {invitedUsers, acceptedUsers, } = useUserData();

  const [selectedUser, setSelectedUser] = useState<Invitation | undefined>(undefined);
  const [userSearch, setUserSearch] = useState("");

  const [showInvites, setShowInvites] = useState(false);


  return (
    <>
      <EditReferee 
        selectedUser={selectedUser} 
        removeSelected={() => setSelectedUser(undefined)} 
        clubId={club?.id || ""} 
      />
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={1}>
        <Stack direction={"row"} alignItems={"center"} sx={{ whiteSpace: "nowrap", pt: 1, pl: 1}}>
          <OpenSideBarButton />
          <Typography variant="h6" fontWeight="bold" >
            Användare
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing={5} justifyContent={"space-between"} alignItems={"center"} display={{xs: "none", md: "flex"}}>
          {showInvites && <Button variant="contained" size="large" onClick={() => setShowInvites(false)}>
          Avbryt
          </Button>}
          {invitedUsers.length !== 0 && <Paper
            sx={{ p: '10px 8px', display: 'flex', alignItems: 'center', backgroundColor: Colors.primary, cursor: "pointer" }}
            onClick={() => setShowInvites(true)}
          >
            <Typography  sx={{ whiteSpace: 'nowrap'}} color="secondary">{invitedUsers.length} Förfrågningar</Typography>
          </Paper>}
          <Paper
            sx={{ p: '10px 8px', display: 'flex', alignItems: 'center' }}
          >
            <Typography sx={{ whiteSpace: 'nowrap',}} color="textSecondary">{acceptedUsers.length} Användare</Typography>
          </Paper> 
          <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center',  }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Sök"
              inputProps={{ 'aria-label': 'sök' }}
              value={userSearch}
              onChange={e => setUserSearch(e.target.value)}
            />
            <IconButton type="button" aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>        
        </Stack>
      </Stack>
      {showInvites ? 
          <UserTable
          data={invitedUsers}
          columns={trainerColumns}
          onRowClick={(user) => setSelectedUser(user)}
        />
      :
      acceptedUsers.length > 0 && <UserTable
          data={acceptedUsers.filter(referee => referee.name.toLocaleLowerCase().includes(userSearch.toLocaleLowerCase()))}
          columns={trainerColumns}
          onRowClick={(user) => setSelectedUser(user)}
        />}
    </>
  );
};
