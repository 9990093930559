import { Match, MatchInvite } from "@monorepo/shared";
import { useEffect, useState } from "react";
import { MatchWithRefereeInfo } from "../types/types";
import { useMatches } from "contexts/MatchesContext";
import { useReferees } from "contexts/RefereesContext";

export const usePaymentData = () => {

  const { matches, matchInvitations } = useMatches();
  const {referees} = useReferees();

  const [unpaid, setUnpaid] = useState<MatchWithRefereeInfo[]>([]);
  const [paid, setPaid] = useState<MatchWithRefereeInfo[]>([]);

  useEffect(() => {
    if (!referees || referees.length <= 0 || !matches) {
      return
    }
    const payments = matches
      .filter(match => new Date(match.time) < new Date())
      .reduce((acc: MatchWithRefereeInfo[], match: Match) => {
        const invitesArray = (matchInvitations[match.id!] || [])
          .filter(inv => referees.find(referee => referee.id === inv.refereeId))
          .map((invite) => {
            const referee = referees.find(referee => referee.id === invite.refereeId)!; 
            const accountNumber = (referee.clearingNumber || "")+ "-" + referee.accountNumber;
            return ({...match, ...invite, accountNumber, phoneNumber: referee.phoneNumber, socialSecurityNumber: referee.socialSecurityNumber})
          });
        return [...acc, ...invitesArray];
      }, []);
      
    const unpaidMatches = payments.filter((match) => match.status === 'done');
    const paidMatches = payments.filter((match) => match.status === 'paid');

    setUnpaid(unpaidMatches);
    setPaid(paidMatches);
  }, [matches, referees, matchInvitations]);

  return {unpaid, paid}
}