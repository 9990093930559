import { Typography, Avatar, IconButton, Stack, Paper, InputBase, Badge} from "@mui/material";

import dayjs, { Dayjs } from "dayjs";

import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useEffect, useRef, useState } from "react";
import { matchWarning } from "features/matches/utils/matchWarning";
import { MatchEditor } from "features/matches/components/MatchEditor";
import { useInvitations } from "contexts/InvitationsContext";
import { addDoc, collection, Firestore, Timestamp } from "firebase/firestore";
import { useUser } from "contexts/UserContext";
import { Image, InsertDriveFile } from "@mui/icons-material";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { ChatMessage, useChat } from "contexts/ChatContext";
import { paths, UserRole } from "@monorepo/shared";
import { useClub } from "contexts/ClubContext";
import { db, storage } from "config/firebase/firebase";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentIcon from '@mui/icons-material/Attachment';


export const Chat = () => {

  const {user, currentInvitation} = useUser();
  const {club} = useClub();
  const {chatMessages} = useChat();
  
  const [text, setText] = useState("");

  const chatRef = useRef<HTMLDivElement | null>(null);

  const [file, setFile] = useState<File>();

  useEffect(() => {
    // Scroll to bottom on new messages
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [chatMessages]); // Runs when messages change

  const uploadFile = async (file: File, path: string) => {
    const storageRef = ref(storage, path);
  
    console.log("Storage reference:", storageRef);
  
    try {
      const snapshot = await uploadBytes(storageRef, file);
      console.log("Upload successful:", snapshot);
      return getDownloadURL(snapshot.ref);
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log("Selected file:", file.name);
      setFile(file);
    }
  };

  const roleToText = (role: UserRole) => {
    switch (role) {
      case "responsible": 
        return "Domar ansvarig";
      case "trainer":
        return "Tränare";
      default:
        return "Domare";
    }
  } 

  const sendMessage = async () => {
    if (!user || !user.name || !user.id || !currentInvitation?.role || !club?.id || text === "") return
    const message: ChatMessage = {
      timeStamp: Timestamp.fromDate(new Date()),
      sender: user.name,
      senderId: user.id,
      role: roleToText(currentInvitation?.role),
      message: text
    }

    try {
      if (file) {
        message.attachmentType = "pdf";
        const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9_.-]/g, "_");
        const path = `chat_files/${sanitizedFileName}-${Date.now()}`;
  
        message.attachmentUrl = await uploadFile(file, path);
      }
  
      const messageRef = collection(db, paths.clubs, club.id, "chatMessages");
      await addDoc(messageRef, message);
      setText("");
      setFile(undefined);
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  }

  return (
    <>
      <Typography variant="h6" fontWeight="bold">Klubbchatt</Typography>
        <Stack spacing={1} mt={2} sx={{ flex: 1, overflowY: "auto", p: 1, minHeight: 0, }} ref={chatRef}>
          {chatMessages.map((msg, index) => {
            const isCurrentUser = msg.senderId === user?.id;

            return (
              <Paper
                key={index}
                sx={{
                  p: 1.5,
                  borderRadius: 2,
                  bgcolor: isCurrentUser ? "primary.light" : "grey.100",
                  alignSelf: isCurrentUser ? "flex-end" : "flex-start",
                  maxWidth: "75%",
                }}
              >
                {/* Sender info */}
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Avatar sx={{ width: 24, height: 24 }}>{msg.sender[0]}</Avatar>
                  <Typography variant="subtitle2" fontWeight="bold" color={!isCurrentUser ? "black" : "white"}>{msg.sender}</Typography>
                  <Typography variant="caption" sx={{ bgcolor: "white", px: 1, borderRadius: 1, fontSize: "0.7rem" }}>
                    {msg.role}
                  </Typography>
                  <Typography variant="caption" color={!isCurrentUser ? "textSecondary" : "white"}>
                    {dayjs(msg.timeStamp.toDate()).format("HH:mm")}
                  </Typography>
                </Stack>

                {/* Message */}
                <Typography variant="body2" sx={{ mt: 0.5 }} color={!isCurrentUser ? "black" : "white"}>{msg.message}</Typography>

                {/* Attachment (if exists) */}
                {msg.attachmentUrl && (
                  <IconButton
                    component="a"
                    href={msg.attachmentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ mt: 1 }}
                  >
                    {msg.attachmentType === "pdf" ? <InsertDriveFile color={!isCurrentUser ? "primary" : "secondary"} /> : <Image color={!isCurrentUser ? "primary" : "secondary"} />}
                  </IconButton>
                )}
              </Paper>
            );
          })}
        </Stack>

        {/* Message Input */}
        <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
          <input
            type="file"
            id="file-input"
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />
          <IconButton sx={{ p: "10px" }} aria-label="menu" onClick={() => {setFile(undefined);document.getElementById("file-input")?.click()}}>
            <Badge badgeContent={file ? 1 : 0} color="primary" >
              <AttachmentIcon/>
            </Badge>
          </IconButton>
          <InputBase 
            sx={{ ml: 1, flex: 1 }} 
            placeholder="Skriv ett meddelande" 
            value={text}
            onChange={e => setText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) { // Check if Enter is pressed
                e.preventDefault();
                sendMessage();
              }
            }}  
          />
          <IconButton color="primary" sx={{ p: "10px" }} aria-label="send" disabled={text === ""} onClick={sendMessage}>
            <SendRoundedIcon />
          </IconButton>
        </Paper>

    </>
  )
}