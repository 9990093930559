import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fade, List, ListItem, ListItemIcon, ListItemText, Modal, Paper, Stack, TextField, Typography, } from "@mui/material"
import { useCallback, useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { InvitationStatus, paths, UserRole } from "@monorepo/shared";
import { db } from "config/firebase/firebase";
import { collection, doc, getDocs, query, updateDoc, where, writeBatch } from "firebase/firestore";
import { ClubEditor } from "./ClubEditor";
import { useUser } from "contexts/UserContext";
import { roleToText } from "shared/utils/roles";

interface PersonalSettingsProps {

}

export const PersonalSettings = ({}: PersonalSettingsProps) => {

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [clubSearch, setClubSearch] = useState("");
  const [activeClub, setActiveClub] = useState("")

  const [openClubEditor, setOpenClubEditor] = useState(false);

  const {userInvitations, user, isAdmin} = useUser();
  const [takenClubs, setTakenClubs] = useState<{value: string, label: string}[]>([])

  useEffect(() => {
    try {
      (async () => {
        const clubRef = collection(db, paths.clubs);
        const clubs = (await getDocs(clubRef)).docs
          .map(doc => ({value: doc.id, label: doc.data()["clubName"]}))
        setTakenClubs(clubs || [])
      })()
    } catch (error) {
      console.error("Error fetching taken clubs: ", error);
    }
  }, [])

  const reset = useCallback(() => {
    setName(user?.name || "");
    setPhone(user?.phoneNumber || "");
  }, [user])

  useEffect(() => {
    reset()
  }, [reset])

  const saveActiveClub = async () => {
    const userRef = user ? doc(db, paths.clubUsers, user.id!) : null;
    if (!user || !userRef) return;
    await updateDoc(userRef, {
      activeClub: activeClub,
    })
    setActiveClub("");
  }

  const savePersonalSettings = async () => {
    try {
      const userRef = user ? doc(db, paths.clubUsers, user.id!) : null;
      if (!user || !userRef) return;
      const invitationsQuery = query(
        collection(db, paths.invitations),
        where("userId", "==", user.id)
      );    
  
      const batch = writeBatch(db);
  
      batch.update(userRef, {
        name: name,
        phoneNumber: phone,
      });
  
      const invitationsSnapshot = await getDocs(invitationsQuery);
      invitationsSnapshot.forEach((doc) => {
        batch.update(doc.ref, {
          name: name, 
          phoneNumber: phone,
        });
      });
  
      await batch.commit();
    } catch (error) {
      console.error("Error saving personal setings: ", error);
    }
  }



  return (
    <Box height={"100%"} overflow={"scroll"}>
      <Dialog open={activeClub !== ""} onClose={() => setActiveClub("")}>
        <DialogTitle>
          Byt till klubb: {userInvitations.find(invite => invite.clubId === activeClub)?.clubName || ""}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Ändrar vilka matcher och domare som kommer att synas
          </Typography>
        </DialogContent>
          <Divider />
        <DialogActions>
          <Button variant="contained" color="error" onClick={() => setActiveClub("")}>Avbryt</Button>
          <Button variant="contained" onClick={saveActiveClub}>Acceptera</Button>
        </DialogActions>
      </Dialog>
      <Modal disableAutoFocus={true} closeAfterTransition open={openClubEditor} onClose={() => setOpenClubEditor(false)} >
        <Fade in={openClubEditor}>
          <Paper sx={{width: {md: "600px", xs: "100%", marginTop: 300, padding: 10}, justifySelf: "center", alignSelf: "center"}} >
            <ClubEditor onClose={() => setOpenClubEditor(false)}/>
          </Paper>
        </Fade>
      </Modal>
      <List component="div" disablePadding>
        <ListItem sx={{ pl: 8 }}>
          <ListItemText primary= "Personuppgifter" />
        </ListItem>
        <ListItem sx={{ pl: 12 }}>
          <TextField 
          value={name}
            onChange={e => setName(e.target.value)}
            onBlur={savePersonalSettings}
            variant="standard"
            label="Namn (Förnamn och Efternamn)"
            fullWidth        
          />
        </ListItem>
        <ListItem sx={{ pl: 12 }}>
          <TextField 
            value={phone}
            onBlur={savePersonalSettings}
            onChange={e => setPhone(e.target.value)}
            variant="standard"
            label="Telefonnummer"
            fullWidth        
          />
        </ListItem>
        <ListItem sx={{ pl: 8, pt: 10}}>
          <ListItemText primary="Mina klubbar"/>
          <Button variant="contained" onClick={() => setOpenClubEditor(true)}><AddIcon /></Button>
        </ListItem>
        <ListItem sx={{ pl: 12 }}>
          <TextField variant="standard" label="Klubbar" fullWidth onChange={(e) => setClubSearch(e.target.value)} value={clubSearch}></TextField>
        </ListItem>
        <ListItem sx={{ pl: 12 }}>
          <List sx={{overflowY: "auto", width:"100%"}}>
          {!isAdmin ? userInvitations.filter(invitation => invitation.clubName.toLocaleLowerCase().includes(clubSearch.toLocaleLowerCase())).map(invitation => {
              return (
                <ListItem
                  divider 
                  key={invitation.id}
                  onPointerDown={() => setActiveClub(invitation.clubId)} 
                >

                  <Stack direction={"row"} width={"100%"} sx={{justifyContent: "space-between", flexDirection: 1}} alignItems={"center"} >
                    <ListItemIcon>{invitation.clubId === user?.activeClub && <CheckIcon/>}</ListItemIcon>
                    <ListItemText>{invitation.clubName || ""}</ListItemText>
                    <ListItemText sx={{textAlign: "right"}}>{roleToText(invitation.role, invitation.status)}</ListItemText>
                  </Stack>
                </ListItem>
              ) 
            }) : 
              takenClubs.map(({value, label}) => (
                <ListItem
                  divider 
                  key={value}
                  onPointerDown={() => setActiveClub(value)} 
                >

                  <Stack direction={"row"} width={"100%"} sx={{justifyContent: "space-between", flexDirection: 1}} alignItems={"center"} >
                    <ListItemIcon>{value === user?.activeClub && <CheckIcon/>}</ListItemIcon>
                    <ListItemText>{label || ""}</ListItemText>
                    <ListItemText sx={{textAlign: "right"}}>Admin</ListItemText>
                  </Stack>
                </ListItem>
              ))
            } 
          </List>
        </ListItem>
      </List>
    </Box>
  )
}