import React from "react";

import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';
import ClearIcon from '@mui/icons-material/Clear';
import PersonOffIcon from '@mui/icons-material/PersonOff';

import { Match, MatchInvite, refereeStatus } from "@monorepo/shared";
import { statusColor, statusToTitle } from "../utils/refereeStatus";
import { Chip, Stack, Tooltip } from "@mui/material";

export const refereeIcon = (status: refereeStatus, tab: boolean) => {
  let color:  "default" | "warning" | "success" | "info" | "error" = statusColor(status, tab);

  switch (status) {
    case "invite": return <EmailIcon color={color !== "default" ? color : "inherit"}/>;
    case "accepted": return tab ? <MarkEmailReadIcon color={color !== "default" ? color : "inherit"}/> : <HourglassBottomRoundedIcon color={color !== "default" ? color : "inherit"}/>;
    case "done": return <AttachMoneyIcon color={color !== "default" ? color : "inherit"}/>; 
    case "paid": return <PriceCheckIcon color={color !== "default" ? color : "inherit"}/>; 
    case "declined": return <ClearIcon color={color !== "default" ? color : "inherit"}/>;
    case "withdrawn": return <PersonOffIcon color={color !== "default" ? color : "inherit"}/>
  }
}

export const refereeChip = (invite: MatchInvite, amount: number, matchTab: boolean) => {

  return (
    <Tooltip title={statusToTitle(invite.status, matchTab)} key={invite.name}>
      <Chip 
        sx={{justifyContent: "space-between", opacity: 0.6, flexDirection: "row-reverse", width: "100%", paddingRight: 1}} 
        color={statusColor(invite.status, matchTab)} size="small"  
        icon={refereeIcon(invite.status, matchTab)} label={`${invite.name} ${amount ? "+" + amount : "" }`}
      />
    </Tooltip>
  )
}

export const displayInvites = (matchTab: boolean, invites?: MatchInvite[], match?: Match) => {
  const filteredInvites: MatchInvite[] = (invites || []).filter(invite => invite.status);
  if (!filteredInvites || filteredInvites.length < 0) {
    return null;
  }

  const amount = filteredInvites.length

  if (match?.everyone) {
    return (
      <Tooltip title={"Inbjudan"}>
        <Chip 
          sx={{justifyContent: "space-between", opacity: 0.6, flexDirection: "row-reverse", width: "100%", paddingRight: 1}} 
          color={statusColor("invite", matchTab)} size="small"  
          icon={refereeIcon("invite", matchTab)} label={`Alla ${amount ? "+" + amount : "" }`}
        />
      </Tooltip>
    )
  }
  // Sort invites based on certain criteria

  return (
    <Stack spacing={0.2}>
      {filteredInvites.slice(0, 1).map(invite => (
        refereeChip(invite, amount - 1, matchTab)
      ))}
    </Stack>
  )
}