// RefereesContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { collection, query, where, onSnapshot, Timestamp, orderBy } from 'firebase/firestore';
import { db } from '../config/firebase/firebase';
import { Referee, paths } from '@monorepo/shared';
import { useClub } from './ClubContext';
import { useInvitations } from './InvitationsContext';
import { getDocuments } from 'utils/getDocs';
import { addSubscription } from "./ManageSubscriptions";
import { useUser } from './UserContext';

export type ChatMessage = {
  timeStamp: Timestamp;
  sender: string;
  senderId: string;
  role: "Domar ansvarig" | "Domare" | "Tränare";
  message: string;
  attachmentUrl?: string; 
  attachmentType?: "image" | "pdf" | "other";
  id?: string, 
};

interface ChatState {
  chatMessages: ChatMessage[];
}

const ChatContext = createContext<ChatState | undefined>(undefined);

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { club } = useClub();
  const {currentInvitation} = useUser();
  const { invitations } = useInvitations();
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);

  useEffect(() => {
    if (!club || club.id === "") return;

    try {
      const chatRef = query(collection(db, paths.clubs, club.id, "chatMessages"), orderBy("timeStamp"));
  
      const unsubscribe = onSnapshot(chatRef, async () => {
        const messages = await getDocuments(chatRef) as ChatMessage[];
        setChatMessages(messages)
      
      });
  
      addSubscription(unsubscribe)
  
      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching chat messages: ", error);
    }
  }, [club, currentInvitation?.role, invitations]);

  return <ChatContext.Provider value={{chatMessages }}>{children}</ChatContext.Provider>;
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};
