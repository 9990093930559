import * as React from 'react';
import { Colors, paths } from "@monorepo/shared"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, Stack, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material"
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db } from '../../../config/firebase/firebase';
import { useScreenSize } from '../hooks/screenSize';
import { ColorButton } from './ColorButton';
import { ContactForm } from 'shared/components/ContactForm';


const steps = ["Kontaktuppgifter", "Meddelande", "Klar!"];

export const ContactDialog = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <Box>
      <ColorButton
        size="large"
        onClick={() => setOpen(true)}
        sx={{
          fontSize: { xs: "0.8rem", sm: "1rem" },
          padding: { xs: "8px 16px", sm: "10px 20px" },
        }}
      >
        Kontakta oss!
      </ColorButton>
      <ContactForm open={open} setOpen={setOpen}/>
    </Box>
  );
};

export const ContactUs = () => {

  const [contactInfo, setContactInfo] = React.useState({name: "", topic: "", email: "", message: ""})

  const {smallScreen} = useScreenSize();

  async function saveMessage() {
    if (Object.values(contactInfo).some(val => val === "")) {
      return; 
    }
    try {
      const messageRef = collection(db, paths.messages);
      const data = {
        ...contactInfo,
        timeStamp: Timestamp.fromDate(new Date())
      };    
      console.log(data)
      await addDoc(messageRef, data);
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  }

  return (
    <Grid2 container padding={"2%"} height={"100%"} spacing={0} rowSpacing={{xs: 5, md: 30}}>
      <Grid2 size={{xs: 12, md: 4}}>
        <Box>
          <Typography variant="h2" sx={{ color: Colors.primary, marginBottom: '20px' }}>
            Kontakta Oss!
          </Typography>
          <Typography variant="body1" sx={{ color: Colors.darkBlue, marginBottom: '10px' }}>
            Vi finns här för att hjälpa er.
          </Typography>
          <Typography variant="body1" sx={{ color: Colors.darkBlue, marginBottom: '10px' }}>
            Tveka inte att kontakta oss om ni har frågor, funderingar genom att skicka ett mejl till: kontakt@reffi.se  

          </Typography>
          <Typography variant="body1" sx={{ color: Colors.darkBlue, marginBottom: '40px' }}>
            Vi välkommnar varmt alla förbättrings förslag! För att göra ett så bra system som möjligt för er!  

          </Typography>
        </Box>
      </Grid2>
      <Grid2 size={{xs: 0, md: 3}}> 
      </Grid2>
      <Grid2 size={{xs: 12, md: 5}}>
        <Stack spacing={3}>
          <Stack direction={"row"} justifyContent={"space-between"} spacing={5}>
            <TextField id="contactName" fullWidth label="Namn" onChange={e => setContactInfo({...contactInfo, name: e.target.value})} required/>
            <TextField id="contactFamilyName" fullWidth label="Ämne" onChange={e => setContactInfo({...contactInfo, topic: e.target.value})} required/>
          </Stack>
          <TextField id="contactMail" label="Mail" required onChange={e => setContactInfo({...contactInfo, email: e.target.value})} />
          <TextField id="contactMessage" label="Meddelande" required multiline rows={3} onChange={e => setContactInfo({...contactInfo, message: e.target.value})}/>
          <Button variant="contained" sx={{width: 100, borderRadius: 30}} onClick={saveMessage}>Skicka</Button>
        </Stack>
      </Grid2>
      {!smallScreen && <Grid2 size={4}>
        <Typography variant="h2" sx={{ color: Colors.primary, marginBottom: '20px' }}>
          Reffi
        </Typography>
      </Grid2>}
      <Grid2 size={{xs: 0, md: 5}}></Grid2>
      <Grid2 size={3}>
          <Stack>
            <Typography variant={smallScreen ? "h6" : "h5"} sx={{ color: Colors.darkBlue, marginBottom: '10px' }}>
              Omgången 411
            </Typography>
            <Typography variant={smallScreen ? "h6" : "h5"} sx={{ color: Colors.darkBlue, marginBottom: '10px' }}>
              41280 Göteborg              
            </Typography>
            <Typography variant={smallScreen ? "h6" : "h5"} sx={{ color: Colors.darkBlue, marginBottom: '10px' }}>
              E-post:  kontakt@reffi.se
            </Typography>              
          </Stack>
      </Grid2>
    </Grid2>
  )
}