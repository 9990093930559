import { DataGrid, GridColDef, GridPrintExportOptions, useGridApiRef } from "@mui/x-data-grid";
import { usePaymentData } from "../hooks/usePaymentData";
import { filterBetweenDates } from "../utils/timeUtils";
import dayjs, { Dayjs,  } from 'dayjs';
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "contexts/AppContext";
import { DateTab } from "./DateTab";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { Colors, formatTime } from "@monorepo/shared";
import { OpenSideBarButton } from "shared/components/OpenSideBarButton";
import { DatePicker } from "@mui/x-date-pickers";

const columns: GridColDef[] = [
  { field: 'id', headerName: '', width: 30, },
  { field: 'name', headerName: 'Namn', flex: 2 },
  { field: 'time', headerName: 'Datum', flex: 1 },
  { field: 'accountNumber', headerName: 'Bankkontonummer', flex: 1 },
  { field: 'compensation', headerName: 'Ersättning', headerAlign: "right",align: "right", width: 100},
];

const columnsPDF: GridColDef[] = [
  { field: 'id', headerName: '', width: 30, },
  { field: 'name', headerName: 'Namn', flex: 1 },
  { field: 'time', headerName: 'Datum', width: 150 },
  { field: 'accountNumber', headerName: 'Bank nummer', flex: 1 },
  { field: 'compensation', headerName: 'Ersättning',  headerAlign: "right",align: "right", width: 100},
];


export const PaymentReport = () => {

  const {paid} = usePaymentData();

  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(30, 'day'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  const apiRef = useGridApiRef(); 
  const wrapperRef = useRef();


  const getRows = () => paid.filter((match) => filterBetweenDates(match, startDate, endDate)).map((match, i) => {
    const paymentDetails = match.paid!;
    return {
      ...match,
      time: formatTime(paymentDetails.date),
      compensation: paymentDetails.amount + "kr",
      id: i + 1,
    }
  })

  const print = async () => {    
    const printOptions: GridPrintExportOptions = {
      hideFooter: true,
      hideToolbar: true,
    };
    apiRef.current.resetRowHeights();
    apiRef.current.setDensity("compact");
    apiRef.current.resize();
    await new Promise(resolve => setTimeout(resolve, 100)); 

    apiRef.current.exportDataAsPrint(printOptions)
  }

  return (
    <Box sx={{ width: '100%', height: "100%" }} ref={wrapperRef}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" p={1}>
        <Stack direction={"row"} alignItems={"center"} sx={{ whiteSpace: "nowrap", pt: 1, pl: 1}}>
          <OpenSideBarButton />
          <Typography variant="h6" fontWeight="bold" >
            Betalningsrapport

          </Typography>
        </Stack>
        <Typography variant="h6" fontWeight="bold" sx={{ whiteSpace: "nowrap"}} pl={2}>
        </Typography>
        <Stack direction="row" spacing={1.5} alignItems="center">

          <DatePicker 
            value={startDate} 
            onChange={(newDate) => setStartDate(newDate!)} 
            label="Från: " 
            sx={{
              '& .MuiInputBase-root': { height: 45}
            }}
            />
          <DatePicker
            value={endDate}
            onChange={(newDate) => setEndDate(newDate!)}
            label="Till:"
            sx={{
              '& .MuiInputBase-root': { height: 45}
            }}
          />
          <Divider orientation="vertical" flexItem  />
          <Button variant="contained" color="secondary" sx={{ color: Colors.primary }} onClick={() => print()}>
            Generera pdf
          </Button>
        </Stack>
      </Stack>
      <Divider  />
      <DataGrid
        rows={getRows()}
        rowSelection={false}
        disableColumnFilter
        disableColumnMenu
        columns={columns}
        pageSizeOptions={[5, 10]}
        localeText={
          {noRowsLabel: "Inga betalningar i den angivna tidsperioden", 
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `${from}-${to} av ${count === -1 ? `mer än ${to}` : count}`,
            },
          }}
        sx={{ border: 0 }}
      />

      <div style={{width: "900px"}}>
        <DataGrid
          apiRef={apiRef}
          rows={getRows()}
          rowSelection={false}
          disableColumnFilter
          disableColumnMenu
          columns={columnsPDF}
          pageSizeOptions={[5, 10]}
          localeText={
            {noRowsLabel: "Inga betalningar i den angivna tidsperioden", 
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  `${from}-${to} av ${count === -1 ? `mer än ${to}` : count}`,
              },
            }}
          sx={{ border: 0, display: "hidden" }}
        />
      </div>
    </Box>
  )
}