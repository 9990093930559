import { Box, Button, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Stack, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase/firebase";
import { paths } from "@monorepo/shared";
import { useClub } from "contexts/ClubContext";

interface BankSettingsProps {

}

export const BankSettings = ({} : BankSettingsProps) => {

  const {club} = useClub();

  const [bic, setBic] = useState("");
  const [orgNumber, setOrgNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const reset = useCallback(() => {
    setBic(club?.settings.BIC || "")
    setOrgNumber(club?.settings.orgNumber || "");
    setAccountNumber(club?.settings.accountNumber || "");
  }, [club])

  useEffect(() => {
    reset()
  }, [reset])

  
  const saveSettings = async () => {
    try {
      const clubRef = club ? doc(db, paths.clubs, club.id!) : null;
      if (!club || !clubRef) return;
  
      await updateDoc(clubRef, {
        "settings.BIC": bic,
        "settings.orgNumber": orgNumber,
        "settings.accountNumber": accountNumber,
      });
    } catch (error) {
      console.error("Error saving bank settings: ", error);
    }
  }


  return (
    <Box padding={3}>
      <Typography variant="h5" padding={2}>
        Dessa uppgifter är frivilliga att fylla i men krävs för att kunna generera en betalfil för utbetalning till domare.
      </Typography>
      <Stack padding={3} spacing={2}>
        <TextField label="BIC Nummer" placeholder="AAABBCCDDD" value={bic}  onChange={e => setBic(e.target.value)}></TextField>
        <TextField label="Organisations nummer" placeholder="xxxxxx-xxxx" value={orgNumber} onChange={e => setOrgNumber(e.target.value)}></TextField>
        <TextField label="Kontonummer" placeholder="clearing - kontonummer" value={accountNumber} onChange={e => setAccountNumber(e.target.value)}></TextField>
        <Button onClick={saveSettings} variant="contained">Spara Bank uppgifter</Button>
      </Stack>
    </Box>
  )
}