import { Button, IconButton } from "@mui/material";
import { useAppContext } from "contexts/AppContext";
import MenuIcon from '@mui/icons-material/Menu';
import { useState, useEffect } from "react";

export const OpenSideBarButton = ({inverted} : {inverted?: boolean}) => {
  const { openSideBar, setOpenSideBar } = useAppContext();

  return (
    <Button
      variant="contained"
      onClick={() => setOpenSideBar(true)}
      color={inverted ? "secondary" : "primary"}
      size="small"
      sx={{
        mr: 2,
        minWidth: "36px", // Ensures a square shape
        minHeight: "36px", // Same as minWidth to make it square
        opacity: openSideBar ? 0 : 1,
        transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
        ...(!openSideBar ? {} : { display: "none" }),
      }}
    >
      <MenuIcon color={!inverted ? "secondary" : "primary"}/>
    </Button>
  );
};