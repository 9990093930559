import * as React from 'react';
import { Colors, paths, User } from "@monorepo/shared"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, Stack, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material"
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db } from 'config/firebase/firebase';
import { ColorButton } from 'features/landingpage/components/ColorButton';



const steps = ["Kontaktuppgifter", "Meddelande", "Klar!"];

export const ContactForm = ({open, setOpen, user}: {open: boolean, setOpen: (val: boolean) => void, user?: User}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [contactInfo, setContactInfo] = React.useState({
    name:  "",
    email:  "",
    message: "",
  });

  React.useEffect(() => {
    if (user) {
      setContactInfo({
        name: user.name || "",
        email: user.email || "",
        message: "",
      });
    }
  }, [user]);

  const handleNext = () => {
    if (activeStep === 0 && (!contactInfo.name || !contactInfo.email)) {
      alert("Fyll i namn och e-post/telefon.");
      return;
    }
    if (activeStep === 1 && !contactInfo.message) {
      alert("Meddelande krävs.");
      return;
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
    setContactInfo({ name: "", email: "", message: "" });
  };

  const saveMessage = async () => {
    const messageRef = collection(db, "messages");
    const data = {
      ...contactInfo,
      timeStamp: Timestamp.fromDate(new Date()),
    };
    await addDoc(messageRef, data);
    handleNext();
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ textAlign: "center" }}>
          <Typography component="div" variant="h4" color={Colors.primary}>
            {activeStep === steps.length - 1
              ? "Tack för ditt meddelande!"
              : "Kontakta Oss"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 3 }}>
            {activeStep === 0 && (
              <Stack spacing={2}>
                <TextField
                  label="Namn"
                  fullWidth
                  value={contactInfo.name}
                  onChange={(e) =>
                    setContactInfo({ ...contactInfo, name: e.target.value })
                  }
                />
                <TextField
                  label="E-post eller Telefon"
                  fullWidth
                  value={contactInfo.email}
                  onChange={(e) =>
                    setContactInfo({ ...contactInfo, email: e.target.value })
                  }
                />
              </Stack>
            )}
            {activeStep === 1 && (
              <TextField
                label="Meddelande"
                fullWidth
                multiline
                rows={4}
                value={contactInfo.message}
                onChange={(e) =>
                  setContactInfo({ ...contactInfo, message: e.target.value })
                }
              />
            )}
            {activeStep === 2 && (
              <Typography variant="body1" textAlign="center">
                Tack för ditt meddelande! Vi återkommer så snart vi kan.
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {activeStep === 1 && (
            <Button onClick={handleBack} color="primary">
              Tillbaka
            </Button>
          )}
          {activeStep < steps.length - 1 && (
            <Button
              variant="contained"
              onClick={activeStep === steps.length - 2 ? saveMessage : handleNext}
              sx={{ borderRadius: 30 }}
            >
              {activeStep === steps.length - 2 ? "Skicka" : "Nästa"}
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Button onClick={handleClose} variant="contained" sx={{ borderRadius: 30 }}>
              Stäng
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};