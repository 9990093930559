import { Match } from "@monorepo/shared";

export const sortMatches = (func: (a: Match, b: Match) => number) => {
  return (a: Match, b: Match) => {
    const result = func(a, b);
    if (result !== 0) return result; // If the custom function provides a sorting order, use it
    const numA = parseInt(a.id!.split("_")[1], 10);
    const numB = parseInt(b.id!.split("_")[1], 10);

    return numA - numB; // Sort by extracted numeric ID
  };
};