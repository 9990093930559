import { Colors } from "@monorepo/shared";
import { Box, ListItem, ListItemText } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

interface SidebarTabProps {
  title: string;
  icon?: React.ReactNode;
  path: string;
}

export const SideBarTab = ({title, icon, path}: SidebarTabProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSelected = location.pathname === path;          

  return (
    <ListItem 
      key={path} 
      onClick={() => navigate(path)} 
        sx={{ 
          color: Colors.white,
          borderRadius: "8px",
          paddingLeft: 1.5,
          pb: 0.6,
          backgroundColor: isSelected ? Colors.white : "transparent",
          paddingTop: 0,
          paddingBottom: 0,
          boxShadow: isSelected ? "0px 4px 8px rgba(0, 0, 0, 0.2)" : "none", // Elevation effect
        }}
    >
      <Box sx={{ color: isSelected ? Colors.primary : Colors.white, display: "flex", alignItems: "center", mr: 1 }}>
        {icon}
      </Box>
      <ListItemText primary={title} sx={{ color: isSelected ? Colors.primary : Colors.white, marginTop:0, marginBottom: 0, padding:"5px" }} />
    </ListItem>
  )
    
}