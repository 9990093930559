import { DocumentData, DocumentReference, getDoc, getDocs, Query } from "firebase/firestore";



export async function  getDocuments(ref: Query<DocumentData, DocumentData>) {
  try {
    const data = await getDocs(ref);
    return data.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching documents:", error);
    return []; // Return an empty array to prevent errors in consuming functions
  }
}

export async function getDocument(ref: DocumentReference<DocumentData, DocumentData>) {
  try {
    const doc = await getDoc(ref);
    if (!doc.exists()) {
      return null;
    }
    return {
      id: doc.id,
      ...doc.data(),
    };
  } catch (error) {
    console.error("Error fetching document:", error);
    return null; // Return null to indicate failure
  }
}