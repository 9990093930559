import React, { createContext, useContext, useEffect, useState } from 'react';
import { collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../config/firebase/firebase';
import { User, Invitation, paths } from '@monorepo/shared';
import { getDocument, getDocuments } from 'utils/getDocs';
import { addSubscription } from "./ManageSubscriptions";

interface UserState {
  user: User | undefined;
  currentInvitation: (User & Invitation) | undefined;
  userInvitations: Invitation[];
  loadingUser: boolean;
  loadingCurrenInvitation: boolean;
  isAdmin: boolean;
}

const UserContext = createContext<UserState | undefined>(undefined);

export const UserProvider: React.FC<{ userId: string; children: React.ReactNode }> = ({ userId, children }) => {
  const [user, setUser] = useState<User | undefined>();
  const [currentInvitation, setCurrentInvitation] = useState<(User & Invitation) | undefined>();
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingCurrenInvitation, setLoadingCurrenInvitation] = useState(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [userInvitations, setUserInvitations] = useState<Invitation[]>([])

  useEffect(() => {
    if (!userId) return;
    
    try {setLoadingUser(true);
      const userRef = doc(db, paths.clubUsers, userId);

      const unsubscribeUser = onSnapshot(userRef, async () => {
        const userDoc = await getDocument(userRef) as User;
        setUser({ ...userDoc});
        setLoadingUser(false);

      });

      addSubscription(unsubscribeUser);

      return () => {
        unsubscribeUser();
      };
    } catch (error) {
      console.error("Error fetching user: ", error);
    }
  }, [userId]);

  useEffect(() => {
    if (!userId || !user) return;
    
    try {
      setLoadingCurrenInvitation(true);
  
      const invitationRef = query(collection(db, paths.invitations), where("userId", "==", userId));
  
      const unsubscribeInvitation = onSnapshot(invitationRef, async () => {
        const invitations = await getDocuments(invitationRef) as Invitation[];
        setUserInvitations(invitations);
        if (user && user.activeClub) {
          const invitation = invitations.find(invitation => user.activeClub === invitation.clubId);
          if (invitation) {
            setCurrentInvitation({...user, ...invitation})
          }
        }
        setLoadingCurrenInvitation(false)
      })
      
      addSubscription(unsubscribeInvitation);
  
      return () => {
        unsubscribeInvitation();
      };
    } catch (error) {
      console.error("Error fetching user invitation: ", error);
    }
  }, [user, userId]);

  useEffect(() => {
    if (!userId) return;

    try {
      const adminRef = doc(db, "adminUsers", userId);

      const unsubscribeAdmin = onSnapshot(adminRef, (adminDoc) => {
        setIsAdmin(adminDoc.exists());
      });

      addSubscription(unsubscribeAdmin);

      return () => {
        unsubscribeAdmin();
      };
    } catch (error) {
      console.error("Error checking admin status: ", error);
    }
  }, [userId]);

  useEffect(() => {
    if (!isAdmin || !user?.activeClub) {
      return
    }

    const invitation: (User & Invitation) = {...user, role: "responsible", status: "accepted", userId: user.id, clubId: user.activeClub, clubName : ""}

    setCurrentInvitation(invitation)
  }, [isAdmin, user])

  return <UserContext.Provider value={{ user, loadingCurrenInvitation, loadingUser, currentInvitation, userInvitations, isAdmin }}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
