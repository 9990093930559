import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { CssBaseline, } from '@mui/material';
import { LandingPage } from './routes/LandingPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { auth, db } from '../config/firebase/firebase';
import { signOut, User } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { Colors, paths } from '@monorepo/shared';
import { ClubStateProvider } from '../contexts/clubStateContext';
import { GlobalStyles } from "@mui/material";
import { SignIn } from 'features/landingpage/components/SignIn';
import { SignUp } from 'features/landingpage/components/SignUp';
import { Users } from './routes/Users';
import { Schedule } from './routes/Schedule';
import { Payments } from './routes/Payments';
import Home from './routes/Home';
import { PaymentReport } from 'features/payments/components/PaymentReport';
import { Unpaid } from 'features/payments/components/Unpaid';
import { AccountingReport } from 'features/payments/components/AccountingReport';
import { Coming } from 'features/matches/components/Coming';
import { Played } from 'features/matches/components/Played';
import { Examples } from 'features/matches/components/Examples';
import { Referees } from 'features/users/components/Referees';
import { Trainers } from 'features/users/components/Trainers';
import { SettingsModal } from './routes/Settings';
import { Welcome } from './routes/Welcome';
import { TrainerSchedule } from 'app/routes/TrainerSchedule';
import { UserProvider } from 'contexts/UserContext';
import { ClubProvider } from 'contexts/ClubContext';
import { InvitationsProvider } from 'contexts/InvitationsContext';
import { RefereesProvider } from 'contexts/RefereesContext';
import { MatchesProvider } from 'contexts/MatchesContext';
import { Help } from './routes/Help';
import { PersonalSettings } from 'features/settings/components/PersonalSettings';
import { GeneralSettings } from 'features/settings/components/GeneralSettings';
import { BankSettings } from 'features/settings/components/BankSettings';
import { Questions } from 'features/settings/components/Questions';
import { Subscriptions } from 'features/settings/components/Subscriptions';
import { Statistics } from 'features/users/components/Statistics';
import { AppProvider } from 'contexts/AppContext';
import Overview from './routes/Overview';
import { ChatProvider } from 'contexts/ChatContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5D97E5', // Change the primary color
      contrastText: '#FFF'
    },
    secondary: {
      contrastText: '#FFF',
      main: '#FFF', // Change the secondary color
    },
  },
});

const App = () => {
  const [user, setUser] = React.useState<User | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [isClubUser, setIsClubUser] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false); 
    });
    return () => unsubscribe();
  }, []);

  React.useEffect(() => {
    if (!location.pathname.includes("home")) {
      return;
    }
    if (!loading && user) {
      let retryCount = 0;
      const checkClubUser = async () => {
        try {
          retryCount++;
          if (retryCount > 10) {
            await signOut(auth);
            navigate('/');
            return;
          }
          // Check for the club user document
          const clubUserDoc = await getDoc(doc(db, paths.clubUsers, user.uid));
          if (clubUserDoc.exists()) {
            setIsClubUser(true);
          } else {
            console.log("Club user not found, retrying in 1 second...");
            setTimeout(checkClubUser, 1000); // Retry after 1 second
          }
        } catch (error) {
          console.error('Error checking user type:', error);
          await signOut(auth);
          navigate('/');
        }
      };
  
      checkClubUser();
    } else if (!loading && !user) {
      navigate('/');
    }
  }, [loading, user, navigate, location.pathname]);

  return (
  <AppProviders userId={user ? user.uid : ""}>
    <GlobalStyles
      styles={{
        "html, body, #root": {
          margin: 0,
          padding: 0,
          height: "100%",
          backgroundColor: Colors.primary, // Ensure root background color
          color: Colors.white, // Optional: Set text color for contrast
        },
        "::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "::-webkit-scrollbar-track": {
          background: Colors.white,
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: Colors.primary,
        },
        "::-webkit-scrollbar-thumb:hover": {
          backgroundColor: Colors.darkBlue,
        },
        backgroundColor: Colors.primary,
      }}
    />    
    
    <Routes>
      <Route path="/" element={<LandingPage />}>
        <Route path="/login" element={<SignIn />}/>
        <Route path="/signup" element={<SignUp />}/>
      </Route>
      <Route path="welcome" element={<Welcome />}>
      </Route>
      <Route path="help" element={<Help />}>
      </Route>
      <Route path="settings" element={<SettingsModal />}>
        <Route index element={<Navigate to="personal" replace />} />
        <Route path="*" element={<Navigate to="personal" />} />
          <Route path="personal" element={<PersonalSettings />} />
          <Route path="general" element={<GeneralSettings />} />
          <Route path="subscriptions" element={<Subscriptions />} />
          <Route path="bank" element={<BankSettings />} />
          <Route path="questions" element={<Questions />} />
        </Route>
      <Route path="/home/" element={<Home />}>
        <Route index element={<Navigate to="overview" replace />} />
        <Route path="*" element={<Navigate to="overview" />} />

        <Route path="overview" element={<Overview />} />

        <Route path="schedule" element={<Navigate to="schedule/coming" replace />} />
        <Route path="schedule/coming" element={<Coming />} />
        <Route path="schedule/played" element={<Played />} />
        <Route path="schedule/examples" element={<Examples />} />

        <Route path="users" element={<Navigate to="users/referees" replace />} />
        <Route path="users/referees" element={<Referees />} />
        <Route path="users/trainers" element={<Trainers />} />
        <Route path="users/statistics" element={<Statistics />} />

        <Route path="payments" element={<Navigate to="payments/unpaid" replace />} />
        <Route path="payments/unpaid" element={<Unpaid />} />
        <Route path="payments/accounting" element={<AccountingReport />} />
        <Route path="payments/report" element={<PaymentReport />} />
        
        <Route path="trainer" element={<TrainerSchedule />}>
        </Route>

      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </AppProviders>
  );
};

const AppProviders: React.FC<{ children: React.ReactNode, userId: string }> = ({ userId, children }) => {
  return (
    <UserProvider userId={userId}>
      <ClubProvider>
        <InvitationsProvider>
          <RefereesProvider>
            <ChatProvider>
              <AppProvider>
                <MatchesProvider>{children}</MatchesProvider>
              </AppProvider>
            </ChatProvider>
          </RefereesProvider>
        </InvitationsProvider>
      </ClubProvider>
    </UserProvider>
  );
};

export default function Root() {
  return (
    <Router basename='/'>
      <ThemeProvider theme={theme}>
        <CssBaseline /> 
            <App />
      </ThemeProvider>
    </Router>
  );
}
