import { Match } from "@monorepo/shared";
import { useClub } from "contexts/ClubContext";
import { useMatches } from "contexts/MatchesContext";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";



export const useStatistics = (startDate: Dayjs, endDate: Dayjs) => {

  const { matches, matchInvitations } = useMatches();
  const {club} = useClub();

  const [monthlyPayments, setMonthlyPayments] = useState<number[]>([]);
  const [monthlyUnpaid, setMonthlyUnpaid] = useState<number[]>([]);
  const [monthlyPredicted, setMonthlyPredicted] = useState<number[]>([]);


  const [statusDistribution, setStatusDistribution] = useState<{ [key: string]: number }>({});
  const [months, setMonths] = useState<string[]>([]) 
  const [paymentInfo, setPaymentInfo] = useState({paid: 0, unpaid: 0, predicted: 0});
  const[matchChartData, setMatchChartData] = useState<{
    label: string;
    data: number[];
    id: string,
}[]>();

  useEffect(() => {
    const distribution: { [key: string]: number } = {};
    const matchesGroupedByMonth: { [date: string]: Match[] } = {};
    const paymentsGroupedByMonth: { [date: string]: number } = {};
    const unpaidGroupedByMonth: { [date: string]: number } = {};
    const predictedGroupedByMonth: { [date: string]: number } = {};



    matches
      .filter(match => startDate.isBefore(dayjs(match.time)))
      .filter(match => endDate.isAfter(dayjs(match.time)))
      .forEach(match => {
        const monthKey = dayjs(match.time).startOf("month").format("YYYY-MM");

        // Group matches by month
        if (!matchesGroupedByMonth[monthKey]) {
          matchesGroupedByMonth[monthKey] = [];
        }
        matchesGroupedByMonth[monthKey].push(match);

        // Group payments by month
        if (!paymentsGroupedByMonth[monthKey]) {
          paymentsGroupedByMonth[monthKey] = 0;
        }

        if (!unpaidGroupedByMonth[monthKey]) {
          unpaidGroupedByMonth[monthKey] = 0;
        }

        if (!predictedGroupedByMonth[monthKey]) {
          predictedGroupedByMonth[monthKey] = 0;
        }

        (matchInvitations[match.id!] || []).forEach(inv => {
          if (inv.paid) {
            const amountPaid = inv.paid.amount || 0;

            paymentsGroupedByMonth[monthKey] += amountPaid;
            predictedGroupedByMonth[monthKey] += amountPaid;
          } else {
            predictedGroupedByMonth[monthKey] += match.compensation || 0;
            if (inv.status === "done") {
              unpaidGroupedByMonth[monthKey] += match.compensation || 0;
            } 
          }

          if (inv.status) {
            distribution[inv.status] = (distribution[inv.status] || 0) + 1;
          }
        });
      });

    // Convert data for charts
    const months = Object.keys(matchesGroupedByMonth).sort();
    // Create series data
    const matchesSeries = Object.entries(club?.settings.matchTypes || {}).map(([type, {displayValue}]) => ({
      data: months.map(month => matchesGroupedByMonth[month]?.filter(m => m.periodLength?.toString() === (type || 0)).length || 0),
      label: displayValue,
      id: type,
    })) 

    const paid = Object.values(paymentsGroupedByMonth).reduce((acc, val) => acc + val, 0);
    const unpaid = Object.values(unpaidGroupedByMonth).reduce((acc, val) => acc + val, 0);
    const predicted = Object.values(predictedGroupedByMonth).reduce((acc, val) => acc + val, 0);

    setPaymentInfo({paid, predicted, unpaid})
    setStatusDistribution(distribution);
    setMonths(months)
    setMatchChartData( matchesSeries );
    setMonthlyPayments(months.map(month => paymentsGroupedByMonth[month] || 0))
    setMonthlyUnpaid(months.map(month => unpaidGroupedByMonth[month] || 0))
    setMonthlyPredicted(months.map(month => predictedGroupedByMonth[month] || 0))

  }, [matches, startDate, endDate, matchInvitations, club?.settings.matchTypes]);

  return {monthlyPayments, monthlyUnpaid, monthlyPredicted, statusDistribution, months, paymentInfo, matchChartData}
}