

import { Box, Button, Card, CardActions, CardContent, Collapse, Grid2, InputAdornment, LinearProgress, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Club, Colors, formatDate, formatTime, paths } from "@monorepo/shared";
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, Timestamp } from "firebase/firestore";
import { db, functions, payments } from "config/firebase/firebase";
import { getDocument } from "utils/getDocs";
import { createCheckoutSession } from "@invertase/firestore-stripe-payments";
import { httpsCallable } from "firebase/functions";
import { useClub } from "contexts/ClubContext";
import { useUser } from "contexts/UserContext";

interface SubscriptionProps {
}


export const Subscriptions = ({}: SubscriptionProps) => {

  const { club, clubSubscription} = useClub();
  const {user, currentInvitation} = useUser();

  const [loadingHandleSubs, setLoadingHandleSubs] = useState(false);


  const createPortalLink = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");

  const handlePortal = async () => {
    setLoadingHandleSubs(true);

    try {
      const { data } : any = await createPortalLink({
        returnUrl: window.location.href,
        locale: "auto", // Defaults to "auto"
      });
      window.location.assign(data.url);
    } catch (error: any) {
      console.error("Error creating portal link:", error.message);
      setLoadingHandleSubs(false);
    }
  };

  const handleCheckout = async () => {
    if (!user || !user.activeClub || !clubSubscription) {
      return
    }
    try {

      let price = "";
      switch (clubSubscription.clubSize) {
        case "big":
          price = "price_1Qf196RsBGI96hSIldQOr0dn";
          break;
        case "medium":
          price = "price_1Qf2RjRsBGI96hSIfHBgqRgV";
          break
        default:
          price = "price_1Qf2SSRsBGI96hSIqgR4V3sm";
          break;
      }

      const customerRef = doc(db, "customers", user.id);
      const checkoutRef = collection(customerRef, "checkout_sessions");
      const checkoutDoc = await addDoc(checkoutRef, {
        mode: "subscription",
        price: price,
        success_url: window.location.href,
        cancel_url: window.location.href,
        metadata: { club: user.activeClub },
      });
  
      onSnapshot(checkoutDoc, (doc) => {
        const sessionData = doc.data();
        if (sessionData?.url) {
          window.location.assign(sessionData.url); 
        }
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  }

  const renderContent = () => {
    const noAccess = (clubSubscription?.dueDate.toDate() || new Date()) <= new Date();
    if (clubSubscription?.subscriptionStatus !== "active" || noAccess) {
      return (
        <Box>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: noAccess ? "error.main" : "warning.main",
              color: "common.white",
              mb: 3,
            }}
          >
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Din klubb saknar abonnemang
              </Typography>
              {noAccess ? 
              <Typography variant="body1" gutterBottom>
                Din klubb har inte tillgång till Reffis tjänster
              </Typography> :
              <Typography variant="body1" gutterBottom>
                Tillgång gäller fram till:{" "}
                <strong>
                  {formatDate(clubSubscription?.dueDate.toDate().toISOString() || "")}
                </strong>
              </Typography>
              }
            </CardContent>
            <CardActions>
              <Button
                onClick={handleCheckout}
                variant="contained"
                sx={{ ml: 2 }}
              >
                Teckna nytt abonnemang
              </Button>
            </CardActions>
          </Card>
          <Typography>
            Om ni föredrar en annan betalningsmetod, <Link href={"/help"}>kontakta oss</Link> så löser vi självklart det!
          </Typography>
        </Box>
      );
    }

    if (clubSubscription.canceledAt) {
      return (
        <Card
          variant="outlined"
          sx={{
            backgroundColor: Colors.lightGrey,
            color: Colors.primary,
            mb: 3,
          }}
        >
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Abonnemanget är aktivt men kommer att upphöra
            </Typography>
            <Typography variant="body1" gutterBottom>
              Gäller fram till:{" "}
              <strong>
                {formatDate(clubSubscription?.dueDate.toDate().toISOString() || "")}
              </strong>
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              onClick={handleCheckout}
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
            >
              Förnya abonnemanget
            </Button>
          </CardActions>
        </Card>
      );
    }

    return (
      <Card
        variant="outlined"
        sx={{
          backgroundColor: Colors.primary,
          color: Colors.white,
          mb: 3,
        }}
      >
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Klubbens abonnemang är aktivt
          </Typography>
          <Typography variant="body1" gutterBottom>
            Förnyas automatiskt den:{" "}
            <strong>
              {formatDate(clubSubscription?.previousPayment?.toDate().toISOString() || "")}
            </strong>
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box height="100%" overflow="scroll" padding={4}>
      {/* Header Section */}
      <Box mb={4} textAlign="center">
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          {currentInvitation?.clubName}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Hantera dina fakturor och abonnemang
        </Typography>
      </Box>

      {/* Subscription Status */}
      <Box width={{md: "100%"}} alignSelf={"center"} justifySelf={"center"}>
        {renderContent()} 
      </Box>

      {/* Manage Subscription */}
      <Box textAlign="center" mt={3}>
        {!loadingHandleSubs ? <Button
          onClick={handlePortal}
          variant="outlined"
          sx={{
            borderColor: "primary.main",
            color: "primary.main",
            "&:hover": { backgroundColor: "primary.main", color: "white" },
          }}
        >
          Hantera dina abonnemang
        </Button> : <LinearProgress />}
      </Box>

      {/* Past Invoices (Placeholder for Expansion) */}
      {false && <Box mt={5}>
        <Typography variant="h6" gutterBottom>
          Tidigare fakturor
        </Typography>
        <Grid2 container spacing={2}>
          {[1, 2, 3, 4,5,6,7,8,9,11,12,13,14,21,22,23,24,32,31,34,45,65,76,345].map((invoice) => (
            <Grid2 size={{xs: 12, sm:6, md: 4}}  key={invoice}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Faktura #{invoice}
                  </Typography>
                  <Typography variant="body2">
                    Betald den {formatDate(new Date().toISOString())}
                  </Typography>
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Box>}
    </Box>
  );
}