import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Chip, Divider, IconButton, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Tooltip, Typography } from '@mui/material';
import {  Colors, emptyMatch, Match, MatchInvite, refereeStatus, } from '@monorepo/shared';
import { useMatchData } from 'features/matches/hooks/useMatchData';
import { useAppContext } from 'contexts/AppContext';
import { MatchEditor } from 'features/matches/components/MatchEditor';
import { ScheduleTable } from 'features/matches/components/ScheduleTable';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useOutletContext } from 'react-router-dom';
import { useMatches } from 'contexts/MatchesContext';
import { statusColor, statusToTitle } from '../utils/refereeStatus';
import { groupByRefereeStatus } from '../utils/groupByRefereeStatus';
import { OpenSideBarButton } from 'shared/components/OpenSideBarButton';
import { DisplayRefereeStatus } from './DisplayRefereeStatus';
import { GridSearchIcon } from '@mui/x-data-grid';
export const Played = () => {
  const {played} = useMatchData();
  const {matchInvitations} = useMatches();
  const {setTitle, setTabSettings} = useAppContext();
  const [selectedMatch, setSelectedMatch] = useState<Match>();


    const [divisionSearch, setDivisionSearch] = useState("");
  
    const displayMatches = played.filter(match => match.division.toLocaleLowerCase().includes(divisionSearch.toLocaleLowerCase()));
  
  useEffect(() => {
    setTitle("Spelade matcher");
    setTabSettings(
      <List>
        <ListItem>
          <ListItemButton component="button" onClick={() => setSelectedMatch(emptyMatch())}>
            <ListItemIcon>
              <AddRoundedIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Ny Match" sx={{ textAlign: 'center', color: Colors.white }} />
          </ListItemButton>
        </ListItem>
      </List>
    );
  }, [setSelectedMatch, setTabSettings, setTitle])

  return (
    <>
      <Stack direction="column">
      <Stack direction={"row"} alignItems={"center"} sx={{ whiteSpace: "nowrap", p: 1, pt: 2, pb:0, pl: 2}}>
        <OpenSideBarButton />
        <Typography variant="h6" fontWeight="bold" >
          {played.length} Spelade Matcher
        </Typography>
      </Stack>

        <Stack direction="row" justifyContent="space-between" alignItems="end" p={1} pt={0} pl={2}>
          {/* Referee Status Chips */}
          <DisplayRefereeStatus matches={displayMatches} matchInvitations={matchInvitations} coming={false} /> 


          {/* Buttons */}
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Paper
              component="form"
              sx={{height: 36, display: 'flex', alignItems: 'center',  }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Sök på division"
                inputProps={{ 'aria-label': 'sök' }}
                value={divisionSearch}
                onChange={e => setDivisionSearch(e.target.value)}
              />
              <IconButton type="button" aria-label="search">
                <GridSearchIcon />
              </IconButton>
            </Paper>  
            <Divider orientation="vertical" flexItem  />
            <Tooltip title="Lägg till match">
              <Button variant="contained" color="primary" onClick={() => setSelectedMatch(emptyMatch())}>
                <AddRoundedIcon />
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <MatchEditor open={selectedMatch !== undefined} match={selectedMatch || emptyMatch()} onClose={() => setSelectedMatch(undefined)}/>
      <ScheduleTable matches={displayMatches} matchInvitations={matchInvitations} onSelectMatch={(match) => setSelectedMatch(match)}/>
    </>
  );
};