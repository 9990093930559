import { Match, MatchInvite } from "@monorepo/shared";

export const matchWarning = (match: Match, matchInvitations: {[matchId: string]: MatchInvite[]}) => {
  if (!match.id) {
    return false
  }
  const days3 = new Date();
  days3.setDate(days3.getDate() + 7);
  const matchDate = new Date(match.time); 
  if (isNaN(matchDate.getTime())) return false;
  if (matchDate <= days3 && matchDate > new Date()) {
    const invites = matchInvitations[match.id] || [];
      if (invites.length > 0 && invites.filter(inv => inv.status === "accepted").length < (match.referees || 1)) {
        return true;
      }
  }
  
  return false
}