import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Fade,
  IconButton,
  List,
  ListItemButton,
  Modal,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { useUser } from "contexts/UserContext";
import { PersonalSettings } from 'features/settings/components/PersonalSettings';
import { GeneralSettings } from 'features/settings/components/GeneralSettings';
import { BankSettings } from 'features/settings/components/BankSettings';
import { Questions } from 'features/settings/components/Questions';
import { Subscriptions } from "features/settings/components/Subscriptions";

export const style = {
  position: "absolute" as "absolute",
  borderRadius: 1,
  paddingBottom: 1,
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -30%)",
  boxShadow: 24,
  height: "80vh",
  width: { md: "80vw", xs: "100vw" },
};

export const SettingsModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(true);

  const { currentInvitation } = useUser();

  const [openMenu, setOpenMenu] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [saveState, setSaveState] = useState<"save" | "reset" | undefined>(undefined);

  const handleNavigate = (path: string) => {
    navigate(path);
    if (isMobile) setOpenMenu(false);
  };

  useEffect(() => {
    if (!open) {
      const prevPage = localStorage.getItem("previousPage");
      localStorage.setItem("previousPage", "");
      navigate(prevPage || "/home");
    }
  }, [navigate, open]);

  return (
    <Modal closeAfterTransition open={open} onClose={() => setOpen(false)}>
      <Fade in={open}>
        <Paper sx={{ ...style, display: "flex", flexDirection: "column" }}>
          {/* Header */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ borderBottom: "1px solid lightgray", padding: 2 }}
          >
            {isMobile && !openMenu && (
              <IconButton onClick={() => setOpenMenu(!openMenu)}>
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h5" textAlign="center">
              Inställningar
            </Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>

          <Stack direction="row" flex={1} overflow="hidden">
            {(!isMobile || openMenu) && (
              <List
                sx={{
                  width: isMobile ? "100%" : 300,
                  height: "100%",
                  borderRight: isMobile ? "none" : "1px solid lightgray",
                }}
              >
                {(
                  currentInvitation?.role !== "trainer"
                    ? [
                        { label: "Personliga", path: "/settings/personal" },
                        { label: "Allmänt", path: "/settings/general" },
                        { label: "Medlemsskap", path: "/settings/subscriptions" },
                        { label: "Generera Bankfil", path: "/settings/bank" },
                        { label: "Vanliga frågor", path: "/settings/questions" },
                      ]
                    : [{ label: "Personliga", path: "/settings/personal" }]
                ).map((item, index) => (
                  <ListItemButton
                    key={index}
                    selected={location.pathname === item.path}
                    disabled={item.path === "/settings/bank"}
                    onClick={() => handleNavigate(item.path)}
                    sx={{
                      margin: 1,
                      borderRadius: 1,
                      "&.Mui-selected": {
                        backgroundColor: "primary.light",
                        color: "primary.contrastText",
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: "primary.dark",
                      },
                    }}
                  >
                    {item.label}
                  </ListItemButton>
                ))}
              </List>
            )}

            {(!isMobile || !openMenu) && (
              <Box width="100%" height="100%" maxHeight="100%">
                <Outlet />
              </Box>
            )}
          </Stack>
        </Paper>
      </Fade>
    </Modal>
  );
};
