  import { Box, Button, Checkbox, Collapse, Divider, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Switch, TextField, Tooltip, Typography } from "@mui/material"
  import { useCallback, useEffect, useState } from "react";
  import ExpandLess from '@mui/icons-material/ExpandLess';
  import ExpandMore from '@mui/icons-material/ExpandMore';
  import { Club, Colors, paths } from "@monorepo/shared";
  import InfoIcon from '@mui/icons-material/Info';
  import { getCompensation, getDisplayValue, matchTypes } from "shared/utils/defaultMatchTypes";
  import { doc, updateDoc } from "firebase/firestore";
  import { db } from "config/firebase/firebase";
  import { useClub } from "contexts/ClubContext";
  import { SwitchSetting } from "./SettingsComponents";

  interface GeneralSettingsProps {
   
  }

  export const GeneralSettings = ({}: GeneralSettingsProps) => {    
    const {club} = useClub()
    const [matchTypeSettings, setMatchTypeSettings] = useState(club?.settings.matchTypes || {});
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [referees, setReferees] = useState("");
    const [resend, setResend] = useState(false);
    const [sendCloseTo, setSendCloseTo] = useState(false);
    const [generateAutomatically, setGenerateAutomatically] = useState(false);
    const [splitCompensation, setSplitCompensation] = useState(false);



    const reset = useCallback(() => {
      setMatchTypeSettings(club?.settings.matchTypes || {})
      setEmail(club?.settings.email || "");
      setPhone(club?.settings.phone || "");
      setResend(club?.settings.resendOnDecline || false);
      setSendCloseTo(club?.settings.sendCloseToMatch || false);
      setGenerateAutomatically(club?.settings.generateExamplesAutomatically || false);
      setSplitCompensation(club?.settings.splitCompensation || false);
      setReferees((club?.settings.referees || 1).toString());
    }, [club])

    useEffect(() => {
      reset()
    }, [reset])

    
    const saveSettings = async () => {
      try {
        const clubRef = club ? doc(db, paths.clubs, club.id!) : null;
        if (!club || !clubRef) return;
  
        await updateDoc(clubRef, {
          "settings.matchTypes": matchTypeSettings,
          "settings.email": email,
          "settings.phone": phone,
          "settings.resendOnDecline": resend,
          "settings.sendCloseToMatch": sendCloseTo,
          "settings.referees": parseInt(referees) || 1,
        });
      } catch (error) {
        console.error("Error saving general settings: ", error);
      }
    };

    const saveToggleSetting = async (val: boolean, field: string) => {
      const clubRef = club ? doc(db, paths.clubs, club.id!) : null;
      if (!club || !clubRef) return;

      await updateDoc(clubRef, {
        [field]: val,
      });
    }

    const matchTypeActivated = (id: string) => {
      return matchTypeSettings[id] !== undefined
    }

    const matchTypeCompensation = (id: string) => {
      return matchTypeActivated(id) ? matchTypeSettings[id].compensation : getCompensation(id, club?.clubType);
    }

    const handleCompensationChange = (id: string, value: number) => {
      const updatedSettings = {...matchTypeSettings}

      updatedSettings[id].compensation = value;
      setMatchTypeSettings(updatedSettings);
    }

    const handleMatchClick = (id: string) => {
      const updatedSettings = {...matchTypeSettings}
      if (matchTypeSettings[id] !== undefined) {
        delete updatedSettings[id];
      } else {
        updatedSettings[id] = {compensation: getCompensation(id, club?.clubType), displayValue: getDisplayValue(id, club?.clubType)};
      }
      setMatchTypeSettings(updatedSettings);
    }

    return (
      <Box  height={"100%"} overflow={"scroll"} paddingRight={{xs: 0, md: 10}} paddingLeft={{xs: 0, md: 10}}>
        <List component="div" disablePadding onBlur={() => saveSettings()}>
          <ListItem >
            <ListItemText primary= "Kontaktuppgifter" />
            <Tooltip title="Detta är domarens kontaktperson">
              <InfoIcon color="primary"></InfoIcon>
            </Tooltip>
          </ListItem>
          <ListItem sx={{ pl: 4 }}>
            <TextField 
              value={email}
              onChange={e => setEmail(e.target.value)}
              variant="standard"
              label="Mail"
              fullWidth        
            />
          </ListItem>
          <ListItem sx={{ pl: 4 }}>
            <TextField 
              value={phone}
              onChange={e => setPhone(e.target.value)}
              variant="standard"
              label="Telefonnummer"
              fullWidth        
            />
          </ListItem>
        </List>
        <Paper elevation={3} sx={{margin:2}} >
          <SwitchSetting value={resend} onChange={(val) => saveToggleSetting(val, "settings.resendOnDecline") } 
            title={"Automatisk återinbjudan vid nekande"} 
            description="När en domare avslår en förfrågan, skickar systemet automatiskt en ny inbjudan till en annan behörig domare. Systemet väljer domaren med minst antal dömda matcher. Detta säkerställer en rättvis fördelning av matcher och minskar det administrativa arbetet."
          />
          <Divider />
          <SwitchSetting value={sendCloseTo} onChange={(val) => saveToggleSetting(val, "settings.sendCloseToMatch")} 
            title={"Öppen matchförfrågan tre dagar innan match"} 
            description="Om en domare ej godkänt en matchförfrågan tre dagar innan speldatum, öppnas förfrågan och görs tillgänlig för alla behöriga domare. Detta minskar manuell tillsättning och risken för obemannade matcher."
            />
          <Divider />
          <Tooltip title={"Kommer snart!"}>
            <Box>
              <SwitchSetting value={generateAutomatically} onChange={(val) => {}} 
                title={"Automatisk domartillsättning två veckor före match"} 
                description="Om ingen domare är tillsatt för matchen inom de valda spelformerna, sätts en domare automatiskt till matchen två veckor innan speldatum. Den domare med minst antal matcher tilldelas matchen. Detta gör processen smidigare, så att du slipper lägga till domare manuellt – systemet hanterar det automatiskt."
                />
            </Box>
          </Tooltip>
          <Divider />
          {false && <Tooltip title={"Kommer snart!"}>
            <Box>
              <SwitchSetting value={splitCompensation} onChange={(val) => saveToggleSetting(val, "settings.splitCompensation")} 
                title={"Fördela ersättning jämnt mellan domare"} 
                description="Notera att detta endast påverkar automatiseringar och ersättningen som syns på en match inte delas ytterliggare"
              />
            </Box>
          </Tooltip>}
        </Paper>
        <Box width={"100%"}>
          <TextField 
            value={referees ?? ""} 
            type="number" 
            onBlur={() => saveSettings()}
            variant="standard"
            fullWidth 
            label="Klubbens standard för antal domare på en match"
            inputMode="numeric" 
            onChange={(e) => setReferees(e.target.value === "" ? "" : parseInt(e.target.value) >= 1 ? e.target.value : "1")}  
          />
        </Box>
        <List component="div" disablePadding onBlur={() => saveSettings()}>
          <ListItem sx={{ pl: 0 }}>
            <ListItemText secondary="Här väljer ni vilka spelformer era domare ska döma, samt ersättningen domarna kommer få betalt för en match."  primary="Spelformer och ersättning" />
          </ListItem>
          {matchTypes(club?.clubType).map(matchType => (
            <ListItem key={matchType.uniqueId} sx={{ pl: 4 }}>
              <Checkbox checked={matchTypeActivated(matchType.uniqueId)} onChange={() => handleMatchClick(matchType.uniqueId)} color="primary" sx={{marginRight: 2}} />
              <Typography width={300} paddingRight={5}>
                {matchType.displayValue}: 
              </Typography>
              <TextField 
                variant="standard"
                disabled={!matchTypeActivated(matchType.uniqueId)} 
                onChange={e => handleCompensationChange(matchType.uniqueId, parseInt(e.target.value) || 0)}
                value={matchTypeCompensation(matchType.uniqueId)}   
                fullWidth        
                slotProps={{
                    input: {
                    endAdornment: <InputAdornment position="start">{"kr (per domare)"}</InputAdornment>,
                    },
                }}
            >
            </TextField>

          </ListItem>
          ))}
        </List>
        <Tooltip title="kommer snart">
          <List component="div" disablePadding onBlur={() => saveSettings()}>
              <ListItem sx={{ pl: 0 }}>
                <ListItemText primary="Unika matchtyper" secondary="" />
              </ListItem>
              <ListItemButton>
                Lägg till ny sorts matchtyp
              </ListItemButton>  
          </List>
        </Tooltip>


      </Box>
    )
  }