import React, { createContext, useContext, useEffect, useState } from 'react';
import { doc, onSnapshot, Timestamp } from 'firebase/firestore';
import { db } from '../config/firebase/firebase';
import { Club, paths } from '@monorepo/shared';
import { useUser } from './UserContext';
import { getDocument } from 'utils/getDocs';
import { addSubscription } from "./ManageSubscriptions";

type Subscription = {
  id: string,
  clubName: string, 
  clubSize: string, 
  dueDate: Timestamp, 
  previousPayment: Timestamp,
  subscriptionStatus?: string,
  canceledAt?: boolean,
}

interface ClubState {
  club?: Club;
  clubSubscription?: Subscription;
  loadingClub: boolean;
}

const ClubContext = createContext<ClubState | undefined>(undefined);

export const ClubProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, currentInvitation } = useUser();
  const [club, setClub] = useState<Club | undefined>();
  const [loadingClub, setLoadingClub] = useState(true);
  const [clubSubscription, setClubSubscription] = useState<Subscription>()

  useEffect(() => {
    if (!user || !user.activeClub) {
      return;
    }

    try {
      const subscriptionRef = doc(db, paths.stripePayments, user.activeClub);
  
      const unsubscribeInvoice = onSnapshot(subscriptionRef, async () => {
        setClubSubscription(await getDocument(subscriptionRef) as Subscription);
      });
      addSubscription(unsubscribeInvoice);
      return () => {
        unsubscribeInvoice();
      }
    } catch (error) {
      console.error("Error fetching subscriptions: ", error);
    }
  }, [user])

  useEffect(() => {
    if (!user?.activeClub || !currentInvitation || currentInvitation.status !== "accepted") {
      setClub(undefined);
      return;
    }

    // Prevent access unpaid club
    if (!clubSubscription || clubSubscription.dueDate.toDate() < new Date()) {
      return
    }

    try {
      setLoadingClub(true);
  
  
      const clubRef = doc(db, paths.clubs, user.activeClub);
  
      const unsubscribe = onSnapshot(clubRef, async () => {
        setClub(await getDocument(clubRef) as Club);
        setLoadingClub(false);
      });
      addSubscription(unsubscribe)
      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching club: ", error)
    }
  }, [clubSubscription, currentInvitation, user]);

  return <ClubContext.Provider value={{ club, loadingClub, clubSubscription }}>{children}</ClubContext.Provider>;
};

export const useClub = () => {
  const context = useContext(ClubContext);
  if (!context) {
    throw new Error('useClub must be used within a ClubProvider');
  }
  return context;
};
