import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  Typography,
  IconButton,
  Avatar,
  Stack,
  Divider,
  Tooltip,
  Button,
  ListItemText,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { AccountCircle, Logout, Settings } from "@mui/icons-material";
import { Colors } from "@monorepo/shared";
import SidebarSection from "./SideBarSection";
import { useClub } from "contexts/ClubContext";
import { useUser } from "contexts/UserContext";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { useAppContext } from "contexts/AppContext";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { signOut } from "firebase/auth";
import { clearSubscriptions } from "contexts/ManageSubscriptions";
import { auth } from "config/firebase/firebase";
import { ContactForm } from "./ContactForm";
import { SideBarTab } from "./SideBarTab";
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
interface SidebarProps {
  isClub: boolean;
}

export const Sidebar = ({  isClub }: SidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {openSideBar, setOpenSideBar} = useAppContext();

  const { club } = useClub();
  const { user } = useUser(); 

  const [openContact, setOpenContact] = useState(false);

  return (
    <Stack
      sx={{
        width: openSideBar ? 260 : 0,
        minWidth: openSideBar ? 260 : 0,  
        maxWidth: openSideBar ? 260 : 0,  
        transition: "width 0.3s , min-width 0.3s , max-width 0.3s ",
        flexShrink: 0,  // Prevent it from affecting adjacent content
        bgcolor: Colors.primary, // Keeps background when animating
        height: "100%",  // Ensure it takes full height
        overflowY: "auto",  // Enable vertical scrolling
      }}
    >
      {/* Logo */}
      <Stack direction="row" alignItems="center" justifyContent={"space-between"} gap={1}>
        <Button
          onClick={() => {}}
          variant="text"
          color="secondary"
          sx={{
            padding: 0,
            justifyContent: "flex-start", // Align content to the left
            minWidth: "unset", // Prevent button from stretching
            borderRadius: "8px", // Slightly rounded corners
          }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar
              src={require("../../assets/images/icon.png")}
              sx={{ width: 46, height: 46, borderRadius: "6px" }} // Smaller & rounded
            />
            
            <Typography
              variant="h5"
              color="secondary"
              sx={{ fontStyle: "italic", fontWeight: 500 }}
            >
              REFFI
            </Typography>
            
          </Stack>
        </Button>
        <IconButton size={"medium"} color="secondary" onClick={() => setOpenSideBar(false)}>
          <MenuOpenIcon fontSize="large"/>
        </IconButton>
      </Stack>

      {/* Navigation Links */}
      {isClub && <List>
        <SideBarTab icon={<DashboardRoundedIcon />} title="Överblick" path="/home/overview"/>
        <SidebarSection
          icon={<EditCalendarIcon/>}
          title="Schema"
          basePath="/home/schedule"
          subLinks={[
            { label: "Kommande", path: "/home/schedule/coming" },
            { label: "Spelade", path: "/home/schedule/played" },
          ]}
        />
        <SidebarSection
          title="Vår klubb"
          basePath="/home/users"
          icon={<CottageOutlinedIcon/>}
          subLinks={[
            { label: "Domare", path: "/home/users/referees" },
            { label: "Användare", path: "/home/users/trainers" },
            { label: "Statistik", path: "/home/users/statistics" },
          ]}
        />
        <SidebarSection
          title="Betalningar"
          icon={<AccountBalanceOutlinedIcon/>}
          basePath="/home/payments"
          subLinks={[
            { label: "Obetalt", path: "/home/payments/unpaid" },
            { label: "Betalningsrapport", path: "/home/payments/report" },
          ]}
        />
      </List>}
      {false && <>
      <Divider sx={{backgroundColor: Colors.white, mb: 1}}/>
      <Divider sx={{backgroundColor: Colors.white, mb: 1}}/>
      <Stack spacing={1} mb={5}>
        <Button color="secondary" variant="contained" size="small" sx={{color: Colors.primary, borderRadius: "6px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"}}>
          Ny match
        </Button>
        <Button color="secondary" variant="contained" size="small" sx={{color: Colors.primary, borderRadius: "6px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"}}>
          Generera exempel
        </Button>
      </Stack>
      </>}

      {/* Bottom Section */}
      <Box mt="auto" >
        <Stack direction="row" alignItems="center" gap={1} sx={{ cursor: "pointer", mt: 5, opacity: 0.8 }}>
          <Typography onClick={() => navigate("/settings/")}>Inställningar</Typography>
        </Stack>

        <Stack direction="row" alignItems="center" gap={1} sx={{ cursor: "pointer", mt: 1, opacity: 0.8 }} >
          <Typography onClick={() => window.open("https://www.instagram.com/reffi.se/", "_blank")}>Nyheter</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} sx={{ cursor: "pointer", mt: 1, opacity: 0.8 }} >
          <Typography onClick={() => {navigate("/help")}}>Hjälp</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} sx={{ cursor: "pointer", mt: 1, opacity: 0.8 }} >
          <Typography onClick={() => setOpenContact(true)}>Kontakta oss</Typography>
          <ContactForm open={openContact} setOpen={setOpenContact} user={user} />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} sx={{ cursor: "pointer", mt: 1, opacity: 0.8 }} >
          <Typography onClick={async () => {clearSubscriptions(); await signOut(auth); navigate("/")}}>Logga ut</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} >
          <Typography variant="h5" >
            {club?.clubName} 
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};
